import { Descriptions } from 'antd';
import LOCALS from 'commons/locals';
import { findLabelByValue } from 'commons/options';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { OmsOrderDetail } from 'types/oms';

type RecipientInformationProps = {
  omsOrderDetail: OmsOrderDetail;
};

const RecipientInformation = ({
  omsOrderDetail: {
    receiverName,
    receiverPhone,
    receiverPostCode,
    receiverProvince,
    receiverCity,
    receiverDetailAddress,
  },
}: RecipientInformationProps) => {
  const { countryOptions } = useAppSelector(selectGlobalInfo);

  return (
    <Descriptions
      title={<Trans i18nKey={LOCALS.recipient_information} />}
      bordered
    >
      <Descriptions.Item label={<Trans i18nKey={LOCALS.name} />}>
        {receiverName}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.phone_number} />}>
        {receiverPhone}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.zip_code} />}>
        {receiverPostCode}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.country_region} />}>
        {findLabelByValue(receiverProvince, countryOptions)}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.state_province_city} />}>
        {receiverCity}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.detail_address} />}>
        {receiverDetailAddress}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default RecipientInformation;
