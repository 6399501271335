import { Trans } from 'react-i18next';
import styles from './index.module.scss';
import LOCALS from 'commons/locals';
import { Col, Form, Input, Row, Select, message } from 'antd';
import { UmsMember } from 'types/ums';
import { PmsProduct } from 'types/pms';
import {
  CURRENCY_MAP,
  CURRENCY_OPTION_LIST,
  LANGUAGE_MAP,
  ORDER_CREATED_FROM_OPTION_LIST,
  findLabelByValue,
} from 'commons/options';
import React, { useEffect, useMemo, useState } from 'react';
import { groupBy } from 'lodash-es';
import i18n from 'i18n';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { selectUserInfo } from 'store/slices/userInfoSlice';
import { useTodayRateMap } from 'apis/home';
import { SHOP_MAP } from 'commons/options';
import { getMemberPointRate } from 'apis/ums';

type Props = {
  umsMember?: UmsMember;
  pmsProductList: PmsProduct[];
};

const LABEL_WIDTH_EN = 130;
const LABEL_WIDTH_JP_CN = 100;

// 默认积分返还比例, 1/200
const DEFAULT_POINT_RATE = 0.005;

const CreateOrderDetail = ({ umsMember, pmsProductList }: Props) => {
  const { language } = useAppSelector(selectGlobalInfo);
  const { shop } = useAppSelector(selectUserInfo);

  const todayRateMap = useTodayRateMap();

  // 积分返还比例
  const [pointRate, setPointRate] = useState(DEFAULT_POINT_RATE);
  // 订单币种
  const [currency, setCurrency] = useState(CURRENCY_MAP.JPY);
  // 使用积分
  const [useIntegration, setUseIntegration] = useState(0);
  // 优惠金额
  const [promotionAmount, setPromotionAmount] = useState(0);
  // 订单创建来源，默认网站
  const [createdFrom, setCreatedFrom] = useState(SHOP_MAP.WEBSITE);

  // 动态获取会员的积分比例
  useEffect(() => {
    if (!umsMember?.id) {
      setPointRate(DEFAULT_POINT_RATE);
      return;
    }

    getMemberPointRate(umsMember.id).then(({ data: { pointRate } }) => {
      setPointRate(pointRate);
    });
  }, [umsMember?.id]);

  // 如果账号指定了所属的店铺，则订单创建来源不能修改，和所属店铺绑定
  useEffect(() => {
    if (shop) {
      setCreatedFrom(shop);
    }
  }, [shop]);

  // 合计金额
  const totalAmount = useMemo(() => {
    return pmsProductList.reduce((a, b) => {
      return a + b.price;
    }, 0);
  }, [pmsProductList]);

  // 实际支付金额
  const payAmount = useMemo(() => {
    const rate = todayRateMap[currency];

    let integrationDiscount = 0;

    if (rate) {
      integrationDiscount = Math.floor(useIntegration * rate);
    }

    return totalAmount - promotionAmount - integrationDiscount;
  }, [currency, totalAmount, promotionAmount, todayRateMap, useIntegration]);

  // 实际支付金额，按照当前汇率所转换的日元价格
  const payAmountJpy = useMemo(() => {
    const rate = todayRateMap[currency];

    if (!rate) return 0;

    return Math.floor(payAmount / rate);
  }, [currency, payAmount, todayRateMap]);

  // 赠送积分
  const giftIntegration = useMemo(() => {
    return Math.floor(payAmountJpy * pointRate);
  }, [payAmountJpy, pointRate]);

  useEffect(() => {
    if (!umsMember) {
      setUseIntegration(0);
    }
  }, [umsMember]);

  useEffect(() => {
    const currencies = Object.keys(
      groupBy(pmsProductList, ({ currency }) => currency)
    );

    if (currencies.length === 0) {
      setCurrency(CURRENCY_MAP.JPY);
    } else if (currencies.length === 1) {
      setCurrency(currencies[0]);
    } else {
      message.error(i18n.t(LOCALS.aVYeAxIbsh));
    }
  }, [pmsProductList]);

  const equivalentToEl = useMemo(() => {
    const rate = todayRateMap[currency];
    const currencyLabel: React.ReactNode = findLabelByValue(
      currency,
      CURRENCY_OPTION_LIST
    );

    return (
      <Form.Item label={<Trans i18nKey={LOCALS.points_equivalent} />}>
        <Input
          className={styles.inputNumber}
          value={Math.floor(rate ? useIntegration * rate : 0).toLocaleString()}
          disabled
          suffix={currencyLabel}
        />
      </Form.Item>
    );
  }, [currency, todayRateMap, useIntegration]);

  return (
    <div>
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.order_detail} />
      </div>

      <Form
        labelCol={{
          style: {
            width:
              language === LANGUAGE_MAP.EN ? LABEL_WIDTH_EN : LABEL_WIDTH_JP_CN,
          },
        }}
      >
        <Row gutter={54}>
          <Col span={12}>
            <Form.Item label={<Trans i18nKey={LOCALS.total_amount} />}>
              <Input
                disabled
                type="number"
                value={totalAmount}
                className={styles.inputNumber}
                suffix={findLabelByValue(currency, CURRENCY_OPTION_LIST)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Trans i18nKey={LOCALS.payment_currency} />}>
              <Select
                disabled
                options={CURRENCY_OPTION_LIST}
                value={currency}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={54}>
          <Col span={12}>{equivalentToEl}</Col>
          <Col span={12}>
            <Form.Item label={<Trans i18nKey={LOCALS.used_points} />}>
              <Input
                disabled={!umsMember}
                type="number"
                min={0}
                value={useIntegration}
                onChange={(e) => {
                  setUseIntegration(+e.target.value);
                }}
                className={styles.inputNumber}
                suffix={findLabelByValue(
                  CURRENCY_MAP.JPY,
                  CURRENCY_OPTION_LIST
                )}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={54}>
          <Col span={12}>
            <Form.Item
              labelCol={{
                style: {
                  width:
                    language === LANGUAGE_MAP.EN
                      ? LABEL_WIDTH_EN
                      : LABEL_WIDTH_JP_CN,
                },
              }}
              label={<Trans i18nKey={LOCALS.discount_amounts} />}
            >
              <Input
                type="number"
                min={0}
                className={styles.inputNumber}
                suffix={findLabelByValue(currency, CURRENCY_OPTION_LIST)}
                value={promotionAmount}
                onChange={(e) => {
                  setPromotionAmount(+e.target.value);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={54}>
          <Col span={12}>
            <Form.Item label={<Trans i18nKey={LOCALS.lXECTXrLEx} />}>
              <Input
                disabled
                type="number"
                value={payAmount}
                suffix={findLabelByValue(currency, CURRENCY_OPTION_LIST)}
                className={styles.inputNumber}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Trans i18nKey={LOCALS.convert_to_jpy} />}>
              <Input
                disabled
                type="number"
                value={payAmountJpy}
                suffix={findLabelByValue(
                  CURRENCY_MAP.JPY,
                  CURRENCY_OPTION_LIST
                )}
                className={styles.inputNumber}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={54}>
          <Col span={12}>
            <Form.Item label={<Trans i18nKey={LOCALS.points_earned} />}>
              <Input
                disabled
                type="number"
                value={giftIntegration}
                suffix={findLabelByValue(
                  CURRENCY_MAP.JPY,
                  CURRENCY_OPTION_LIST
                )}
                className={styles.inputNumber}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={<Trans i18nKey={LOCALS.created_from} />}>
              <Select
                value={createdFrom}
                disabled={!!shop}
                options={ORDER_CREATED_FROM_OPTION_LIST}
                onChange={setCreatedFrom}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateOrderDetail;
