import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';
import { createProduct, getProductUpdateInfo, updateProduct } from 'apis/pms';
import LOCALS from 'commons/locals';
import {
  CURRENCY_MAP,
  CURRENCY_OPTION_LIST,
  LANGUAGE_MAP,
  PRODUCT_SOURCE_TYPE_MAP,
  PRODUCT_SOURCE_TYPE_OPTION_LIST,
  PROMOTION_TYPE_MAP,
  STOCK_PLACE_MAP,
  STOCK_PLACE_OPTION_LIST,
} from 'commons/options';
import ImageUploader from 'components/image-uploader';
import { useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { CascaderOption } from 'types/base';
import { ProductCreateInfo, ProductUpdateInfo } from 'types/pms';
import findCascaderOptionById from 'utils/findCascaderOptionById';

// 商品多语言
const copyI18n = (from: any, to: any) => {
  if (!from || !to) return;

  const props = [
    'name', // 商品名称
    'attrRankDesc', // 成色描述
    'attrSize', // 大小
    'subTitle', // 商品列表页标题
    'note', // 商品列表页小标题
    'detailTitle', // 商品详情页标题
    'detailDesc', // 商品详情页描述
  ];

  props.forEach((prop) => {
    to[prop] = from[prop];
  });
};

type ProductAddEditProps = {
  mode: 'add' | 'view' | 'edit';
  id?: number;
};

type ProductAddEditFormData = {
  productCategoryIds: number[];
  attrColorArr: string[];
  attrMaterialArr: string[][];
  attrHardwareArr: string[];
  attrAccessoryArr: string[];
  collectionsArr: string[];
} & ProductUpdateInfo;

// 一些多选值，需要从字符串转成数组并初始化
const transformList = [
  ['attrColor', 'attrColorArr'], // 商品颜色
  ['attrHardware', 'attrHardwareArr'], // 商品金属配件
  ['attrAccessory', 'attrAccessoryArr'], // 商品配件
  ['collections', 'collectionsArr'], // 商品合集
];

const ProductAddEdit = ({ mode, id }: ProductAddEditProps) => {
  const [loading, setLoading] = useState(false);
  const [productMainPic, setProductMainPic] = useState<string[]>([]);
  const [productPics, setProductPics] = useState<string[]>([]);
  const [form] = Form.useForm<ProductAddEditFormData>();
  const [productUpdateInfo, setProductUpdateInfo] =
    useState<ProductUpdateInfo>();
  const navigate = useNavigate();

  const {
    language,
    staffSelectOptions,
    productCategoryCascaderOptions,
    typeSelectOptions,
    rankSelectOptions,
    hueSelectOptions,
    colorSelectOptions,
    materialCascaderOptionsMap,
    hardwareSelectOptions,
    stampSelectOptions,
    accessorySelectOptions,
    collectionSelectOptionsMap,
  } = useAppSelector(selectGlobalInfo);

  const [productCategoryIdFirstLevel, setProductCategoryIdFirstLevel] =
    useState(0);
  const [materialCascaderOptions, setMaterialCascaderOptions] = useState<
    CascaderOption[]
  >([]);

  const collectionSelectOptions = useMemo(() => {
    if (!productCategoryIdFirstLevel) return [];

    if (!Object.keys(collectionSelectOptionsMap).length) return [];

    return collectionSelectOptionsMap[productCategoryIdFirstLevel];
  }, [collectionSelectOptionsMap, productCategoryIdFirstLevel]);

  useEffect(() => {
    if (['view', 'edit'].includes(mode) && id) {
      if (!Object.keys(materialCascaderOptionsMap).length) {
        return;
      }
      setLoading(true);
      getProductUpdateInfo(id)
        .then((res) => {
          const productData = res.data;
          setProductUpdateInfo(productData);

          if (language === LANGUAGE_MAP.JA) {
            copyI18n(productData.i18nJa, productData);
          } else if (language === LANGUAGE_MAP.ZH_CN) {
            copyI18n(productData.i18nCn, productData);
          }

          form.setFieldsValue(productData);

          // 商品分类查找完整路径
          const { productCategoryId } = productData;
          if (productCategoryId) {
            const target = findCascaderOptionById(
              productCategoryId,
              productCategoryCascaderOptions
            );

            if (target) {
              const productCategoryIds = target.treeIds
                ?.split(',')
                .map((i) => +i);

              if (productCategoryIds) {
                const productCategoryIdFirstLevel = productCategoryIds[0];
                const materialCascaderOptions =
                  materialCascaderOptionsMap[productCategoryIdFirstLevel] || [];
                setProductCategoryIdFirstLevel(productCategoryIdFirstLevel);
                setMaterialCascaderOptions(materialCascaderOptions);

                form.setFieldValue('productCategoryIds', productCategoryIds);

                // 商品材质选项依赖于商品分类
                const { attrMaterial } = productData;
                if (attrMaterial) {
                  const tempArr = attrMaterial.split(',');

                  const attrMaterialArr = tempArr.map((material) => {
                    const target = findCascaderOptionById(
                      material,
                      materialCascaderOptions
                    );
                    const tempStrArr = target?.treeIds?.split(',');
                    return tempStrArr;
                  });

                  form.setFieldValue('attrMaterialArr', attrMaterialArr);
                }
              }
            }
          }

          (() => {
            transformList.forEach((item) => {
              const [from, to] = item;
              const value = productData[from as keyof ProductUpdateInfo];

              form.setFieldValue(to, value ? value.split(',') : []);
            });
          })();

          const { pic, albumPics } = productData;
          // 初始化商品主图
          setProductMainPic(pic ? [pic] : []);
          // 初始化商品图片列表
          setProductPics(albumPics ? albumPics.split(',') : []);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      form.setFieldsValue({
        stockPlace: STOCK_PLACE_MAP.JAPAN,
        currency: CURRENCY_MAP.JPY,
        sourceType: PRODUCT_SOURCE_TYPE_MAP.RECYCLE,
        promotionType: PROMOTION_TYPE_MAP.NORMAL,
      });
    }
  }, [
    form,
    id,
    language,
    materialCascaderOptionsMap,
    mode,
    productCategoryCascaderOptions,
  ]);

  const onFinish = () => {
    Modal.confirm({
      title: <Trans i18nKey={LOCALS.confirm_submit} />,
      onOk: async () => {
        let data = form.getFieldsValue();
        let request: typeof createProduct | typeof updateProduct;
        let requestData: ProductCreateInfo & ProductUpdateInfo;

        requestData = {
          ...data,
          productCategoryId:
            data.productCategoryIds[data.productCategoryIds.length - 1],
          attrMaterial:
            data.attrMaterialArr &&
            data.attrMaterialArr
              .map((i) => {
                return i[i.length - 1];
              })
              .join(','),
          pic: productMainPic[0],
          albumPics: productPics.join(','),
        };

        transformList.forEach(([to, from]) => {
          // @ts-ignore
          const fromValue = requestData[from];
          if (fromValue) {
            // @ts-ignore
            requestData[to] = fromValue.join(',');
          }
        });

        if (['view', 'edit'].includes(mode) && id) {
          requestData.id = id;

          // 下面这段逻辑，目的是更新商品多语言文案
          // 英文文案默认存在商品表，其他语言文案需要通过 i18nJa i18nCn 来更新
          // 更新非英语文案时，还需要把参数里边的多语言部分给清空
          if (language === LANGUAGE_MAP.JA) {
            requestData.i18nJa = {
              ...productUpdateInfo?.i18nJa,
            };

            copyI18n(requestData, data.i18nJa);
            copyI18n({}, requestData); // 清空
          } else if (language === LANGUAGE_MAP.ZH_CN) {
            requestData.i18nCn = {
              ...productUpdateInfo?.i18nCn,
            };

            copyI18n(requestData, requestData.i18nCn);
            copyI18n({}, requestData); // 清空
          }

          request = updateProduct;
        } else {
          requestData.i18nCn = {};
          requestData.i18nJa = {};
          requestData.i18nTw = {};
          copyI18n(requestData, requestData.i18nCn);
          copyI18n(requestData, requestData.i18nJa);
          copyI18n(requestData, requestData.i18nTw);

          request = createProduct;
        }

        // @ts-ignore
        await request({
          ...requestData,
          language,
        });

        window.location.reload();
      },
    });
  };

  const onProductMainImageChange = (value: number | null) => {
    if (!value) return;

    const newMainImage = productPics[value - 1];

    if (newMainImage) {
      setProductMainPic([newMainImage]);
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} onFinish={onFinish} labelCol={{ span: 4 }}>
        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="productSn"
              label={<Trans i18nKey={LOCALS.product_sn} />}
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey={LOCALS.required_field} />,
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="owner" label={<Trans i18nKey={LOCALS.staff} />}>
              <Select
                options={staffSelectOptions.map((option) => {
                  return {
                    value: String(option.value),
                    label: option.label,
                  };
                })}
              ></Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="productCategoryIds"
              label={<Trans i18nKey={LOCALS.product_category} />}
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey={LOCALS.required_field} />,
                },
              ]}
            >
              <Cascader
                allowClear={false}
                onChange={(value) => {
                  const target = findCascaderOptionById(
                    value[value.length - 1],
                    productCategoryCascaderOptions
                  );

                  if (target) {
                    form.setFieldValue('name', target.label);
                    form.setFieldValue('attrSize', target.size);
                  }

                  const newProductCategoryIdFirstLevel = +value[0];
                  // 在商品分类第一级变了的情况下，需要重置 attrMaterialArr materialCascaderOptions collectionsArr
                  if (
                    newProductCategoryIdFirstLevel !==
                    productCategoryIdFirstLevel
                  ) {
                    setProductCategoryIdFirstLevel(
                      newProductCategoryIdFirstLevel
                    );
                    setMaterialCascaderOptions(
                      materialCascaderOptionsMap[
                        newProductCategoryIdFirstLevel
                      ] || []
                    );
                    form.setFieldValue('attrMaterialArr', []);
                    form.setFieldValue('collectionsArr', []);
                  }
                }}
                options={productCategoryCascaderOptions}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              label={<Trans i18nKey={LOCALS.product_name} />}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="price"
              label={<Trans i18nKey={LOCALS.price} />}
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey={LOCALS.required_field} />,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="attrType"
              label={<Trans i18nKey={LOCALS.product_type} />}
            >
              <Select options={typeSelectOptions} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="stockPlace"
              label={<Trans i18nKey={LOCALS.stock_place} />}
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey={LOCALS.required_field} />,
                },
              ]}
            >
              <Select options={STOCK_PLACE_OPTION_LIST} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="currency"
              label={<Trans i18nKey={LOCALS.currency} />}
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey={LOCALS.required_field} />,
                },
              ]}
            >
              <Select options={CURRENCY_OPTION_LIST} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="sourceType"
              label={<Trans i18nKey={LOCALS.product_source} />}
            >
              <Radio.Group>
                {PRODUCT_SOURCE_TYPE_OPTION_LIST.map(({ value, label }) => {
                  return (
                    <Radio value={value} key={value}>
                      {label}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="promotionType"
              label={<Trans i18nKey={LOCALS.on_sale} />}
            >
              <Radio.Group>
                <Radio value={PROMOTION_TYPE_MAP.NORMAL}>
                  <Trans i18nKey={LOCALS.no} />
                </Radio>
                <Radio value={PROMOTION_TYPE_MAP.SPECIAL}>
                  <Trans i18nKey={LOCALS.yes} />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item dependencies={['promotionType']} noStyle>
              {({ getFieldValue }) => {
                const promotionType = getFieldValue('promotionType');

                if (promotionType === PROMOTION_TYPE_MAP.SPECIAL) {
                  return (
                    <Form.Item
                      name="originalPrice"
                      label={<Trans i18nKey={LOCALS.original_price} />}
                    >
                      <Input />
                    </Form.Item>
                  );
                }

                return null;
              }}
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="rank"
              label={<Trans i18nKey={LOCALS.rank} />}
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey={LOCALS.required_field} />,
                },
              ]}
            >
              <Select options={rankSelectOptions}></Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="attrRankDesc"
              label={<Trans i18nKey={LOCALS.rank_desc} />}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          labelCol={{ span: 2 }}
          name="attrColors"
          label={<Trans i18nKey={LOCALS.hue} />}
        >
          <Select options={hueSelectOptions} />
        </Form.Item>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="attrColorArr"
              label={<Trans i18nKey={LOCALS.color} />}
            >
              <Select mode="multiple" showSearch options={colorSelectOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="attrColorRemark"
              label={<Trans i18nKey={LOCALS.color_remark} />}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="attrMaterialArr"
              label={<Trans i18nKey={LOCALS.material} />}
            >
              <Cascader options={materialCascaderOptions} multiple />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="attrMaterialRemark"
              label={<Trans i18nKey={LOCALS.material_remark} />}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="attrHardwareArr"
              label={<Trans i18nKey={LOCALS.hardware} />}
            >
              <Select mode="multiple" options={hardwareSelectOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="attrHardwareRemark"
              label={<Trans i18nKey={LOCALS.hardware_remark} />}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="attrStamp"
              label={<Trans i18nKey={LOCALS.stamp} />}
            >
              <Select showSearch options={stampSelectOptions} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="attrStampRemark"
              label={<Trans i18nKey={LOCALS.stamp_remark} />}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="attrAccessoryArr"
              label={<Trans i18nKey={LOCALS.accessories} />}
            >
              <Select mode="multiple" options={accessorySelectOptions} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="attrSize"
              label={<Trans i18nKey={LOCALS.measurement} />}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="subTitle"
              label={<Trans i18nKey={LOCALS.list_title} />}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="note"
              label={<Trans i18nKey={LOCALS.list_remark} />}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          labelCol={{ span: 2 }}
          name="detailTitle"
          label={<Trans i18nKey={LOCALS.detail_title} />}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          labelCol={{ span: 2 }}
          name="detailDesc"
          label={<Trans i18nKey={LOCALS.detailed_page_description} />}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          labelCol={{ span: 2 }}
          name="collectionsArr"
          label={<Trans i18nKey={LOCALS.collections} />}
        >
          <Checkbox.Group>
            {collectionSelectOptions.map(({ value, label }) => {
              return (
                <Checkbox value={value} key={value}>
                  {label}
                </Checkbox>
              );
            })}
          </Checkbox.Group>
        </Form.Item>

        <Form.Item
          labelCol={{ span: 2 }}
          label={<Trans i18nKey={LOCALS.product_main_image} />}
        >
          <div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 6,
              }}
            >
              设置第
              <InputNumber
                onChange={(value) => {
                  onProductMainImageChange(value);
                }}
                defaultValue={1}
                style={{
                  margin: '0 6px',
                }}
              />
              张相册图片为商品主图
            </div>
            <ImageUploader
              mode="single"
              onChange={setProductMainPic}
              imgList={productMainPic}
            />
          </div>
        </Form.Item>

        <Form.Item
          labelCol={{ span: 2 }}
          label={<Trans i18nKey={LOCALS.product_pictures} />}
        >
          <ImageUploader onChange={setProductPics} imgList={productPics} />
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Space>
              <Button onClick={onBack}>
                <Trans i18nKey={LOCALS.back} />
              </Button>
              {(mode === 'edit' || mode === 'add') && (
                <Button type="primary" htmlType="submit">
                  <Trans i18nKey={LOCALS.submit} />
                </Button>
              )}
            </Space>
          </div>
        </Form.Item>
      </Form>
    </Spin>
  );
};

export default ProductAddEdit;
