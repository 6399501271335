import {
  UmsMember,
  UmsMemberPointHistory,
  UmsMemberReceiveAddress,
} from 'types/ums';
import request from '.';
import { BaseRes, PageQuery } from 'types/base';
import { useEffect, useState } from 'react';

export type GetMemberListDto = {
  createSource?: number;
  keyword?: string;
  memberLevel?: string;
} & PageQuery;

export const getMemberList = (params: GetMemberListDto) => {
  return request.get<
    BaseRes<{
      list: UmsMember[];
      total: number;
    }>
  >('/ums/member/list', { params });
};

export const getMemberById = (id: UmsMember['id']) => {
  return request.get<BaseRes<UmsMember>>(`/ums/member/${id}`);
};

type UmsMemberPointHistorySearchDto = {
  memberId?: number;
  orderId?: number;
  startTime?: string;
  endTime?: string;
} & PageQuery;

export const umsMemberPointHistorySearch = (
  data: UmsMemberPointHistorySearchDto
) => {
  return request.post<
    BaseRes<{
      list: UmsMemberPointHistory[];
      total: number;
    }>
  >('/ums/member-point-history/search', data);
};

export const getMemberReceiveAddressList = (memberId: UmsMember['id']) => {
  return request.get<BaseRes<UmsMemberReceiveAddress[]>>(
    `/ums/member/address/default/member/${memberId}`
  );
};

export const useGetMemberReceiveAddressList = (memberId: UmsMember['id']) => {
  const [loading, setLoading] = useState(false);
  const [memberReceiveAddressList, setMemberReceiveAddressList] = useState<
    UmsMemberReceiveAddress[]
  >([]);

  useEffect(() => {
    setLoading(true);
    getMemberReceiveAddressList(memberId)
      .then(({ data }) => {
        setMemberReceiveAddressList(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [memberId]);

  return {
    loading,
    memberReceiveAddressList,
  };
};

export const umsMemberUpdate = (data: UmsMember) => {
  return request.post<BaseRes<null>>('/ums/member/update', data);
};

export const umsMemberCreate = (data: Omit<UmsMember, 'id'>) => {
  return request.post<BaseRes<null>>('/ums/member/create', data);
};

export const getMemberPointRate = (memberId: number) => {
  return request.get<
    BaseRes<{
      isBirthdayMonth: boolean;
      pointRate: number;
    }>
  >('/ums/member-point-rate/getByMemberId', {
    params: {
      memberId,
    },
  });
};

// 积分变更接口
export const umsMemberPointUpdate = (data: {
  memberId: UmsMember['id'];
  type: number;
  point: number;
  operateNote: string;
}) => {
  return request.post('/ums/member/point/update', data);
};
