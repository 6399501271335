import { UmsMember, UmsMemberReceiveAddress } from 'types/ums';
import styles from './index.module.scss';
import { Trans } from 'react-i18next';
import LOCALS from 'commons/locals';
import { useGetMemberReceiveAddressList } from 'apis/ums';
import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { findLabelByValue } from 'commons/options';

type MemberAddressListProps = {
  memberId: UmsMember['id'];
};

const MemberAddressList = ({ memberId }: MemberAddressListProps) => {
  const { memberReceiveAddressList, loading } =
    useGetMemberReceiveAddressList(memberId);
  const { countryOptions } = useAppSelector(selectGlobalInfo);

  const columns: ColumnsType<UmsMemberReceiveAddress> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.name}></Trans>,
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: <Trans i18nKey={LOCALS.country_region}></Trans>,
        dataIndex: 'province',
        key: 'province',
        // 历史遗留问题，country 存到了 province 字段里
        render: (country: UmsMemberReceiveAddress['province']) => {
          return findLabelByValue(country, countryOptions);
        },
      },
      {
        title: <Trans i18nKey={LOCALS.state_province_city}></Trans>,
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: <Trans i18nKey={LOCALS.zip_code}></Trans>,
        dataIndex: 'postCode',
        key: 'postCode',
      },
      {
        title: <Trans i18nKey={LOCALS.detail_address}></Trans>,
        dataIndex: 'detailAddress',
        key: 'detailAddress',
      },
      {
        title: <Trans i18nKey={LOCALS.default_address}></Trans>,
        dataIndex: 'defaultStatus',
        key: 'defaultStatus',
        render: (defaultStatus: UmsMemberReceiveAddress['defaultStatus']) => {
          return defaultStatus ? (
            <Trans i18nKey={LOCALS.yes}></Trans>
          ) : (
            <Trans i18nKey={LOCALS.no}></Trans>
          );
        },
      },
    ];
  }, [countryOptions]);

  return (
    <div>
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.shipping_address} />
      </div>
      <Table
        pagination={false}
        dataSource={memberReceiveAddressList}
        loading={loading}
        rowKey={'id'}
        style={{
          marginTop: 12,
        }}
        columns={columns}
      />
    </div>
  );
};

export default MemberAddressList;
