import { Button, Form, Input, Modal, Select, Space, message } from 'antd';
import {
  omsOrderAddOperationNote,
  omsOrderCancelUpdate,
  omsOrderCompleteUpdate,
  omsOrderDeliverUpdate,
  omsOrderReceiverInfoUpdate,
} from 'apis/oms';
import LOCALS from 'commons/locals';
import { DELIVERY_METHOD_OPTION_LIST, ORDER_STATUS_MAP } from 'commons/options';
import i18n from 'i18n';
import { useState, useCallback } from 'react';
import { Trans } from 'react-i18next';
import { OmsOrderDetail } from 'types/oms';
import styles from './index.module.scss';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { useNavigate } from 'react-router-dom';

type OrderOperateProps = {
  omsOrderDetail: OmsOrderDetail;
};

const OrderOperate = ({
  omsOrderDetail: {
    id,
    status,

    // 收货人信息
    receiverName,
    receiverPhone,
    receiverPostCode,
    receiverProvince,
    receiverCity,
    receiverDetailAddress,
  },
}: OrderOperateProps) => {
  const { countryOptions } = useAppSelector(selectGlobalInfo);
  const navigate = useNavigate();
  const [
    modifyRecipientInformationModalOpen,
    setModifyRecipientInformationModalOpen,
  ] = useState(false);
  const [shipModalOpen, setShipModalOpen] = useState(false);
  const [remarkModalOpen, setRemarkModalOpen] = useState(false);
  const [completeOrderModalOpen, setCompleteOrderModalOpen] = useState(false);
  const [closeOrderModalOpen, setCloseOrderModalOpen] = useState(false);

  const [recipientInformationForm] = Form.useForm();
  const [remarkForm] = Form.useForm();
  const [shipForm] = Form.useForm();
  const [completeOrderForm] = Form.useForm();
  const [closeOrderForm] = Form.useForm();

  const onModifyRecipientInformationOk = useCallback(() => {
    Modal.confirm({
      title: <Trans i18nKey={LOCALS.confirm_submit} />,
      onOk: async () => {
        const data = recipientInformationForm.getFieldsValue();

        await omsOrderReceiverInfoUpdate({
          orderId: id,
          status,
          ...data,
        });

        message.success(i18n.t(LOCALS.successful_operation));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
    });
  }, [id, recipientInformationForm, status]);

  const onRemarkOk = useCallback(async () => {
    const data = await remarkForm.validateFields();

    Modal.confirm({
      title: <Trans i18nKey={LOCALS.confirm_submit} />,
      onOk: async () => {
        await omsOrderAddOperationNote({
          id,
          status,
          ...data,
        });

        message.success(i18n.t(LOCALS.successful_operation));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
    });
  }, [id, remarkForm, status]);

  const onShipOk = useCallback(async () => {
    const data = await shipForm.validateFields();
    Modal.confirm({
      title: <Trans i18nKey={LOCALS.confirm_submit} />,
      onOk: async () => {
        await omsOrderDeliverUpdate([
          {
            orderId: id,
            ...data,
          },
        ]);

        message.success(i18n.t(LOCALS.successful_operation));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
    });
  }, [id, shipForm]);

  const onCompleteOrderOk = useCallback(async () => {
    const data = await completeOrderForm.validateFields();

    Modal.confirm({
      title: <Trans i18nKey={LOCALS.confirm_submit} />,
      onOk: async () => {
        await omsOrderCompleteUpdate({
          id,
          ...data,
        });

        message.success(i18n.t(LOCALS.successful_operation));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
    });
  }, [completeOrderForm, id]);

  const onCloseOrderOk = useCallback(async () => {
    const data = await closeOrderForm.validateFields();

    Modal.confirm({
      title: <Trans i18nKey={LOCALS.confirm_submit} />,
      onOk: async () => {
        await omsOrderCancelUpdate({
          id,
          ...data,
        });

        message.success(i18n.t(LOCALS.successful_operation));
        setTimeout(() => {
          window.location.reload();
        }, 500);
      },
    });
  }, [closeOrderForm, id]);

  return (
    <div>
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.order_operation} />
      </div>
      <div>
        <Space>
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            <Trans i18nKey={LOCALS.back} />
          </Button>

          {/* 修改收件人信息按钮 */}
          {[
            ORDER_STATUS_MAP.TO_BE_PAID,
            ORDER_STATUS_MAP.TO_BE_DELIVERED,
          ].includes(status) && (
            <Button
              onClick={() => {
                setModifyRecipientInformationModalOpen(true);
              }}
            >
              <Trans i18nKey={LOCALS.modify_recipient_information} />
            </Button>
          )}

          {/* 发货按钮 */}
          {[ORDER_STATUS_MAP.TO_BE_DELIVERED].includes(status) && (
            <Button
              onClick={() => {
                setShipModalOpen(true);
              }}
            >
              <Trans i18nKey={LOCALS.ship} />
            </Button>
          )}

          {/* 订单完成按钮 */}
          {[ORDER_STATUS_MAP.DELIVERED].includes(status) && (
            <Button
              onClick={() => {
                setCompleteOrderModalOpen(true);
              }}
            >
              <Trans i18nKey={LOCALS.complete_order} />
            </Button>
          )}

          {/* 订单备注按钮 */}
          <Button
            onClick={() => {
              setRemarkModalOpen(true);
            }}
          >
            <Trans i18nKey={LOCALS.remark} />
          </Button>

          {/* 关闭订单按钮 */}
          {[
            ORDER_STATUS_MAP.TO_BE_PAID,
            ORDER_STATUS_MAP.TO_BE_DELIVERED,
            ORDER_STATUS_MAP.DELIVERED,
          ].includes(status) && (
            <Button
              danger
              type="primary"
              onClick={() => {
                setCloseOrderModalOpen(true);
              }}
            >
              <Trans i18nKey={LOCALS.close_order} />
            </Button>
          )}
        </Space>
      </div>

      <Modal
        title={<Trans i18nKey={LOCALS.modify_recipient_information} />}
        open={modifyRecipientInformationModalOpen}
        onOk={onModifyRecipientInformationOk}
        onCancel={() => {
          setModifyRecipientInformationModalOpen(false);
          recipientInformationForm.resetFields();
        }}
      >
        <Form
          labelCol={{ span: 6 }}
          form={recipientInformationForm}
          initialValues={{
            receiverName,
            receiverPhone,
            receiverPostCode,
            receiverProvince,
            receiverCity,
            receiverDetailAddress,
          }}
        >
          <Form.Item
            name="receiverName"
            label={<Trans i18nKey={LOCALS.name} />}
            rules={[{ required: true }]}
          >
            <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>

          <Form.Item
            name="receiverPhone"
            label={<Trans i18nKey={LOCALS.phone_number} />}
            rules={[{ required: true }]}
          >
            <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>

          <Form.Item
            name="receiverPostCode"
            label={<Trans i18nKey={LOCALS.zip_code} />}
            rules={[{ required: true }]}
          >
            <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>

          <Form.Item
            name="receiverProvince"
            label={<Trans i18nKey={LOCALS.country_region} />}
            rules={[{ required: true }]}
          >
            <Select options={countryOptions} />
          </Form.Item>

          <Form.Item
            name="receiverCity"
            label={<Trans i18nKey={LOCALS.state_province_city} />}
            rules={[{ required: true }]}
          >
            <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>

          <Form.Item
            name="receiverDetailAddress"
            label={<Trans i18nKey={LOCALS.detail_address} />}
            rules={[{ required: true }]}
          >
            <Input.TextArea placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={shipModalOpen}
        title={<Trans i18nKey={LOCALS.ship} />}
        onCancel={() => {
          setShipModalOpen(false);
          shipForm.resetFields();
        }}
        onOk={onShipOk}
      >
        <Form form={shipForm}>
          <Form.Item
            label={<Trans i18nKey={LOCALS.delivery_method} />}
            name="deliveryCompany"
            rules={[{ required: true }]}
          >
            <Select
              options={DELIVERY_METHOD_OPTION_LIST}
              placeholder={i18n.t(LOCALS.please_select)}
            ></Select>
          </Form.Item>
          <Form.Item
            label={<Trans i18nKey={LOCALS.shipment_number} />}
            name="deliverySn"
            rules={[{ required: true }]}
          >
            <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={completeOrderModalOpen}
        title={<Trans i18nKey={LOCALS.complete_order} />}
        onCancel={() => {
          setCompleteOrderModalOpen(false);
        }}
        onOk={onCompleteOrderOk}
      >
        <Form form={completeOrderForm}>
          <Form.Item
            rules={[{ required: true }]}
            name="note"
            label={<Trans i18nKey={LOCALS.remark} />}
          >
            <Input.TextArea placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={remarkModalOpen}
        title={<Trans i18nKey={LOCALS.operation_remark} />}
        onCancel={() => {
          setRemarkModalOpen(false);
          remarkForm.resetFields();
        }}
        onOk={onRemarkOk}
      >
        <Form form={remarkForm}>
          <Form.Item
            name="note"
            label={<Trans i18nKey={LOCALS.remark} />}
            rules={[{ required: true }]}
          >
            <Input.TextArea placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={closeOrderModalOpen}
        title={<Trans i18nKey={LOCALS.close_order} />}
        onCancel={() => {
          setCloseOrderModalOpen(false);
          closeOrderForm.resetFields();
        }}
        onOk={onCloseOrderOk}
      >
        {[
          ORDER_STATUS_MAP.TO_BE_DELIVERED,
          ORDER_STATUS_MAP.DELIVERED,
        ].includes(status) && (
          <div style={{ color: 'red', marginBottom: 6 }}>
            <Trans i18nKey={LOCALS.bzsNZmtrmS} />
          </div>
        )}

        <Form form={closeOrderForm}>
          <Form.Item
            name="note"
            label={<Trans i18nKey={LOCALS.remark} />}
            rules={[{ required: true }]}
          >
            <Input.TextArea placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default OrderOperate;
