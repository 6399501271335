import { selectUserInfo, UserInfoState } from 'store/slices/userInfoSlice';
import {
  AccountBookOutlined,
  FileAddOutlined,
  HomeFilled,
  KeyOutlined,
  ShoppingOutlined,
  SyncOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Trans,useTranslation } from 'react-i18next';
import { groupBy, sortBy, cloneDeep } from 'lodash-es';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import LOCALS from './locals';

export type MenuConfigItem = {
  path: string;
  key?: string;
  icon: JSX.Element;
  label: JSX.Element;
  children?: MenuConfigItem[];
};

// 菜单配置
export const ALL_MENU_CONFIG_ITEMS: MenuConfigItem[] = [
  // 主面板
  {
    path: 'home',
    icon: <HomeFilled />,
    label: <Trans i18nKey={LOCALS.home_page} />,
  },
  // 商品
  {
    path: 'pms',
    icon: <ShoppingOutlined />,
    label: <Trans i18nKey={LOCALS.product} />,
    children: [
      {
        path: 'product', // 商品列表
        icon: <UnorderedListOutlined />,
        label: <Trans i18nKey={LOCALS.product_list} />,
      },
      {
        path: 'product-add',
        icon: <FileAddOutlined />,
        label: <Trans i18nKey={LOCALS.product_add} />,
      },
    ],
  },
  // 订单
  {
    path: 'oms',
    icon: <AccountBookOutlined />,
    label: <Trans i18nKey={LOCALS.order} />,
    children: [
      {
        path: 'order-list',
        icon: <UnorderedListOutlined />,
        label: <Trans i18nKey={LOCALS.order_list} />,
      },
      {
        path: 'order-create',
        icon: <FileAddOutlined />,
        label: <Trans i18nKey={LOCALS.create_order} />,
      },
    ],
  },
  // 会员
  {
    path: 'ums',
    icon: <UserOutlined />,
    label: <Trans i18nKey={LOCALS.member} />,
    children: [
      {
        path: 'member-list',
        icon: <UnorderedListOutlined />,
        label: <Trans i18nKey={LOCALS.member_list} />,
      },
      {
        path: 'member-add',
        icon: <UserAddOutlined />,
        label: <Trans i18nKey={LOCALS.member_sign_up} />,
      },
    ],
  },
  // 回收寄卖
  {
    path: 'rrs',
    icon: <SyncOutlined />,
    label: <Trans i18nKey={LOCALS.recycle} />,
    children: [
      {
        // 网站 sellYourBag 收集到的信息
        path: 'sell-your-bag',
        icon: <UnorderedListOutlined />,
        label: <Trans i18nKey={LOCALS.sell_your_bag} />,
      },
      {
        // 报价表单
        path: 'quotation-form',
        icon: <UnorderedListOutlined />,
        label: <Trans i18nKey={LOCALS.quotation_form} />,
      },
    ],
  },
  // 系统
  {
    path: 'sys',
    icon: <KeyOutlined />,
    label: <Trans i18nKey={LOCALS.system} />,
    children: [
      {
        path: 'admin-list',
        icon: <UnorderedListOutlined />,
        label: <Trans i18nKey={LOCALS.account_list} />,
      },
    ],
  },
  // 订单
  // {
  //   path: 'oms',
  // },
  // // 会员
  // {
  //   path: 'ums',
  // },
  // // 营销
  // {
  //   path: 'sms',
  // },
  // // 内容
  // {
  //   path: 'cms',
  // },
  // // 积分
  // {
  //   path: 'pos',
  // },
];

export const getFilteredMenus = (menus: UserInfoState['menus']) => {
  const filteredMenus: MenuConfigItem[] = [];

  const groupedMenus = groupBy(menus, (menu) => menu.parentId);
  // 获取第一级菜单并排序
  const firstLevelMenus = sortBy(groupedMenus[0] || [], (menu) => {
    return -menu.sort;
  });

  firstLevelMenus.forEach(({ name, id }) => {
    // 查找第一级菜单是否出现在配置里
    const targetMenu = ALL_MENU_CONFIG_ITEMS.find(({ path }) => path === name);
    if (!targetMenu) return;

    const targetMenuCopy = cloneDeep(targetMenu);
    targetMenuCopy.key = `/${targetMenuCopy.path}`;

    // 如果存在 children，还需要过滤 children
    if (targetMenuCopy.children) {
      targetMenuCopy.children = targetMenuCopy.children
        .filter(({ path }) => {
          if (!groupedMenus[id]) return false;

          return !!groupedMenus[id].find(({ name }) => name === path);
        })
        .map((menu) => {
          return {
            ...menu,
            key: `${targetMenuCopy.key}/${menu.path}`,
          };
        });
    }

    filteredMenus.push(targetMenuCopy);
  });

  return filteredMenus;
};

// 校验用户路由权限
export const checkRouteRight = (
  isLoading: boolean,
  menus: UserInfoState['menus'],
  pathname: string
): boolean => {
  if (isLoading) return true;

  if (!menus.length) return false;

  const pathnameArr = pathname.split('/').filter((i) => !!i);
  const [firstLevel, secondLevel] = pathnameArr;

  // 一级菜单
  const targetFirstLevelMenu = menus.find((menu) => {
    return menu.level === 0 && menu.name === firstLevel;
  });

  if (!secondLevel) {
    return !!targetFirstLevelMenu;
  }

  const secondLevelMenus = menus.filter((menu) => {
    return menu.parentId === targetFirstLevelMenu?.id;
  });

  // 二级菜单
  const targetSecondLevelMenu = secondLevelMenus.find((menu) => {
    return menu.name === secondLevel;
  });

  return !!targetSecondLevelMenu;
};

export const getFirstMenuPath = (menus: MenuConfigItem[]) => {
  const [firstMenu] = menus;

  if (!firstMenu) return '';

  if (firstMenu.children && firstMenu.children.length) {
    return firstMenu.children[0].key;
  } else {
    return firstMenu.key;
  }
};

// 根据用户信息的 menus 和 ALL_MENU_CONFIG_ITEMS 配置，获取实际最终用来渲染左侧菜单列表的数据
export const useMenuItems = () => {
  const userInfo = useSelector(selectUserInfo);
  const [menuItems, setMenuItems] = useState<MenuConfigItem[]>([]);

  useEffect(() => {
    setMenuItems(getFilteredMenus(userInfo.menus));
  }, [userInfo.menus]);

  return menuItems;
};
