import { Trans } from 'react-i18next';
import LOCALS from './locals';

export const LANGUAGE_MAP = {
  EN: 'en',
  JA: 'ja',
  ZH_CN: 'zh_CN',
};

export const LANGUAGE_OPTION_LIST = [
  { value: LANGUAGE_MAP.EN, label: 'English' },
  { value: LANGUAGE_MAP.JA, label: 'Japanese' },
  { value: LANGUAGE_MAP.ZH_CN, label: '中文' },
];

export const ACCESSORY_MAP = {
  SHOULDER_STRAP: 1,
  KEYS: 2,
  PADLOCK: 3,
  CLOCHETTE: 4,
  DUSTBAG: 5,
  CITES: 6,
};

export const ACCESSORY_OPTION_LIST = [
  { value: ACCESSORY_MAP.SHOULDER_STRAP, label: 'Shoulder Strap' },
  { value: ACCESSORY_MAP.KEYS, label: 'Keys' },
  { value: ACCESSORY_MAP.PADLOCK, label: 'Padlock' },
  { value: ACCESSORY_MAP.CLOCHETTE, label: 'Clochette' },
  { value: ACCESSORY_MAP.DUSTBAG, label: 'Dustbag' },
  { value: ACCESSORY_MAP.CITES, label: 'Cites' },
];

export const CURRENCY_MAP = {
  JPY: 'JPY',
  HKD: 'HKD',
  SGD: 'SGD',
};

export const CURRENCY_OPTION_LIST = [
  { value: CURRENCY_MAP.JPY, label: <Trans i18nKey={LOCALS.jpy} /> },
  { value: CURRENCY_MAP.HKD, label: <Trans i18nKey={LOCALS.hkd} /> },
  { value: CURRENCY_MAP.SGD, label: <Trans i18nKey={LOCALS.sgd} /> },
];

export const INVENTORY_MAP = {
  IN_STOCK: 1,
  OUT_OF_STOCK: 2,
};

export const INVENTORY_OPTION_LIST = [
  { value: INVENTORY_MAP.IN_STOCK, label: <Trans i18nKey={LOCALS.in_stock} /> },
  {
    value: INVENTORY_MAP.OUT_OF_STOCK,
    label: <Trans i18nKey={LOCALS.out_of_stock} />,
  },
];

export const SOCIAL_MEDIA_MAP = {
  INSTAGRAM: 'instagram',
  WHATSAPP: 'whatsapp',
  LINE: 'line',
  FACEBOOK: 'facebook',
  WECHAT: 'wechat',
  OTHERS: 'others',
};

export const SOCIAL_MEDIA_OPTION_LIST = [
  { value: SOCIAL_MEDIA_MAP.INSTAGRAM, label: 'Instagram' },
  { value: SOCIAL_MEDIA_MAP.WHATSAPP, label: 'WhatsApp' },
  { value: SOCIAL_MEDIA_MAP.LINE, label: 'LINE' },
  { value: SOCIAL_MEDIA_MAP.FACEBOOK, label: 'Facebook' },
  { value: SOCIAL_MEDIA_MAP.WECHAT, label: 'WeChat' },
  { value: SOCIAL_MEDIA_MAP.OTHERS, label: 'Others' },
];

export const CONSULTING_SOURCE_MAP = {
  GINZA_SHOP: 1,
  HONGKONG_SHOP: 2,
  SINGAPORE_SHOP: 3,
  WEBSITE: 4,
  LINE: 5,
  INS: 6,
  WECHAT: 7,
};

export const CONSULTING_SOURCE_OPTION_LIST = [
  { value: CONSULTING_SOURCE_MAP.GINZA_SHOP, label: '银座店' },
  { value: CONSULTING_SOURCE_MAP.HONGKONG_SHOP, label: '香港店' },
  { value: CONSULTING_SOURCE_MAP.SINGAPORE_SHOP, label: '新加坡店' },
  { value: CONSULTING_SOURCE_MAP.WEBSITE, label: 'Website' },
  { value: CONSULTING_SOURCE_MAP.LINE, label: 'LINE' },
  { value: CONSULTING_SOURCE_MAP.INS, label: 'Instagram' },
  { value: CONSULTING_SOURCE_MAP.WECHAT, label: 'WeChat' },
];

export const QUOTATION_FORM_TYPE_MAP = {
  SECOND_HAND: 'second_hand',
  NEW: 'new',
};

export const QUOTATION_FORM_TYPE_OPTION_LIST = [
  { value: QUOTATION_FORM_TYPE_MAP.SECOND_HAND, label: '中古' },
  { value: QUOTATION_FORM_TYPE_MAP.NEW, label: '未使用' },
];

export const STOCK_PLACE_MAP = {
  JAPAN: 'JP',
  HONGKONG: 'HK',
  SINGAPORE: 'SG',
};

export const STOCK_PLACE_OPTION_LIST = [
  {
    value: STOCK_PLACE_MAP.JAPAN,
    label: <Trans i18nKey={LOCALS.japan}></Trans>,
  },
  {
    value: STOCK_PLACE_MAP.HONGKONG,
    label: <Trans i18nKey={LOCALS.hongkong}></Trans>,
  },
  {
    value: STOCK_PLACE_MAP.SINGAPORE,
    label: <Trans i18nKey={LOCALS.singapore}></Trans>,
  },
];

export const PUBLISH_STATUS_MAP = {
  ON_SHELF: 1,
  OFF_SHELF: 0,
};

export const PUBLISH_STATUS_OPTION_LIST = [
  {
    value: PUBLISH_STATUS_MAP.ON_SHELF,
    label: <Trans i18nKey={LOCALS.on_shelf}></Trans>,
  },
  {
    value: PUBLISH_STATUS_MAP.OFF_SHELF,
    label: <Trans i18nKey={LOCALS.off_shelf}></Trans>,
  },
];

export const PROMOTION_TYPE_MAP = {
  // 非特价
  NORMAL: 0,
  // 特价
  SPECIAL: 1,
};

// 商品创建来源
export const PRODUCT_CREATED_FROM_MAP = {
  WEBSITE: 0,
  GINZA: 1,
  HONGKONG: 2,
  SINGAPORE: 3,
  SNS: 4,
};

export const PRODUCT_CREATED_FROM_OPTION_LIST = [
  {
    value: PRODUCT_CREATED_FROM_MAP.WEBSITE,
    label: <Trans i18nKey={LOCALS.website}></Trans>,
  },
  {
    value: PRODUCT_CREATED_FROM_MAP.GINZA,
    label: <Trans i18nKey={LOCALS.ginza}></Trans>,
  },
  {
    value: PRODUCT_CREATED_FROM_MAP.HONGKONG,
    label: <Trans i18nKey={LOCALS.hongkong}></Trans>,
  },
  {
    value: PRODUCT_CREATED_FROM_MAP.SINGAPORE,
    label: <Trans i18nKey={LOCALS.singapore}></Trans>,
  },
  {
    value: PRODUCT_CREATED_FROM_MAP.SNS,
    label: <Trans i18nKey={LOCALS.sns}></Trans>,
  },
];

export const PRODUCT_SOURCE_TYPE_MAP = {
  RECYCLE: 0,
  CONSIGNMENT: 1,
};

export const PRODUCT_SOURCE_TYPE_OPTION_LIST = [
  {
    value: PRODUCT_SOURCE_TYPE_MAP.RECYCLE,
    label: <Trans i18nKey={LOCALS.recycle} />,
  },
  {
    value: PRODUCT_SOURCE_TYPE_MAP.CONSIGNMENT,
    label: <Trans i18nKey={LOCALS.consignment} />,
  },
];

export const MEMBER_LEVEL_MAP = {
  BRONZE: 'BRONZE',
  SILVER: 'SILVER',
  GOLD: 'GOLD',
};

export const MEMBER_LEVEL_OPTION_LIST = [
  { value: MEMBER_LEVEL_MAP.BRONZE, label: MEMBER_LEVEL_MAP.BRONZE },
  { value: MEMBER_LEVEL_MAP.SILVER, label: MEMBER_LEVEL_MAP.SILVER },
  { value: MEMBER_LEVEL_MAP.GOLD, label: MEMBER_LEVEL_MAP.GOLD },
];

export const SHOP_MAP = {
  WEBSITE: 0,
  GINZA: 1,
  HONGKONG: 2,
  SINGAPORE: 3,
  SNS: 4, // 这个店铺实际上不存在，历史遗留原因；多笔支付功能上线之后，理论上就不会有 SNS 的订单
};

export const SHOP_OPTION_LIST = [
  {
    value: SHOP_MAP.WEBSITE,
    label: <Trans i18nKey={LOCALS.website} />,
  },
  {
    value: SHOP_MAP.GINZA,
    label: <Trans i18nKey={LOCALS.ginza} />,
  },
  {
    value: SHOP_MAP.HONGKONG,
    label: <Trans i18nKey={LOCALS.hongkong} />,
  },
  {
    value: SHOP_MAP.SINGAPORE,
    label: <Trans i18nKey={LOCALS.singapore} />,
  },
];

export const SYS_USER_SHOP_OPTION_LIST = [
  {
    value: SHOP_MAP.GINZA,
    label: <Trans i18nKey={LOCALS.ginza} />,
  },
  {
    value: SHOP_MAP.HONGKONG,
    label: <Trans i18nKey={LOCALS.hongkong} />,
  },
  {
    value: SHOP_MAP.SINGAPORE,
    label: <Trans i18nKey={LOCALS.singapore} />,
  },
];

export const ORDER_CREATED_FROM_OPTION_LIST = [
  ...SHOP_OPTION_LIST,
  { value: SHOP_MAP.SNS, label: <Trans i18nKey={LOCALS.sns} /> },
];

export const GENDER_MAP = {
  UNKNOW: 0,
  FEMALE: 1,
  MALE: 2,
};

export const GENDER_OPTION_LIST = [
  {
    value: GENDER_MAP.UNKNOW,
    label: <Trans i18nKey={LOCALS.rather_not_say} />,
  },
  {
    value: GENDER_MAP.FEMALE,
    label: <Trans i18nKey={LOCALS.female} />,
  },
  { value: GENDER_MAP.MALE, label: <Trans i18nKey={LOCALS.male} /> },
];

export const ORDER_STATUS_MAP = {
  TO_BE_PAID: 0,
  TO_BE_DELIVERED: 1,
  DELIVERED: 2,
  COMPLETED: 3,
  CANCLED: 4,
  INVALID_ORDER: 5,
  REFUND_ORDER: 6,
};

export const ORDER_STATUS_ANTD_TAG_COLOR_MAP = {
  [ORDER_STATUS_MAP.TO_BE_PAID]: '#b2c3d1',
  [ORDER_STATUS_MAP.TO_BE_DELIVERED]: '#ff8143',
  [ORDER_STATUS_MAP.DELIVERED]: '#465dd2',
  [ORDER_STATUS_MAP.COMPLETED]: '#27941d',
  [ORDER_STATUS_MAP.CANCLED]: '#ff3b1b',
  [ORDER_STATUS_MAP.INVALID_ORDER]: '#ff3b1b',
  [ORDER_STATUS_MAP.REFUND_ORDER]: '#ff3b1b',
};

export const DELIVERY_METHOD_MAP = {
  SAGAWA_EXPRESS: 'Sagawa Express',
  EMS: 'EMS',
  DHL: 'DHL',
  FEDEX: 'FedEx',
};

export const DELIVERY_METHOD_OPTION_LIST = [
  {
    value: DELIVERY_METHOD_MAP.SAGAWA_EXPRESS,
    label: DELIVERY_METHOD_MAP.SAGAWA_EXPRESS,
  },
  { value: DELIVERY_METHOD_MAP.EMS, label: DELIVERY_METHOD_MAP.EMS },
  { value: DELIVERY_METHOD_MAP.DHL, label: DELIVERY_METHOD_MAP.DHL },
  { value: DELIVERY_METHOD_MAP.FEDEX, label: DELIVERY_METHOD_MAP.FEDEX },
];

export const PAY_STATUS_MAP = {
  CREATED: '1',
  PENDING_PAY: '2',
  PENDING_CONFIRM: '3',
  CONFIRMED: '4',
  FAILED: '5',
};

export const MEMBER_UPDATE_POINT_TYPE_MAP = {
  ACTIVITY: 12,
  SYSTEM: 13,
};

export const MEMBER_UPDATE_POINT_TYPE_OPTION_LIST = [
  { value: MEMBER_UPDATE_POINT_TYPE_MAP.ACTIVITY, label: 'ACTIVITY' },
  { value: MEMBER_UPDATE_POINT_TYPE_MAP.SYSTEM, label: 'SYSTEM' },
];

export const findLabelByValue = (
  value: any,
  options: { value: string | number; label: any }[]
) => {
  return options.find((i) => i.value === value)?.label || '-';
};
