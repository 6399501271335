import { BaseRes } from 'types/base';
import {
  PmsProduct,
  ProductAttributeCode,
  ProductCategory,
  ProductCreateInfo,
  ProductUpdateInfo,
} from 'types/pms';
import request from '.';

export const getProductCategoryTree = () =>
  request.get<BaseRes<ProductCategory[]>>(
    '/pms/productCategory/list/withChildren'
  );

export const getProductAttributeCodeList = () =>
  request.get<
    BaseRes<
      {
        name: string;
        itemList: ProductAttributeCode[];
      }[]
    >
  >('/pms/productAttributeCode/list');

type GetProductListDto = {
  pageSize: number;
  pageNum: number;
  productCategoryId?: number;
  keyword?: string;
  stockPlace?: string;
  isFilterPromotion?: boolean;
  publishStatus?: number;
  transformPriceToJpyFlag?: number;
  productSn?: string;
};

export const getProductList = (data: GetProductListDto) =>
  request.post<
    BaseRes<{
      pageNum: number;
      pageSize: number;
      total: number;
      list: PmsProduct[];
    }>
  >('/pms/product/list', data, {
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize,
    },
  });

export const getProductUpdateInfo = (id: number) => {
  return request.get<BaseRes<ProductUpdateInfo>>(
    `/pms/product/updateInfo/${id}`
  );
};

export const updateProduct = (productUpdateInfo: ProductUpdateInfo) => {
  return request.post<BaseRes<{ data: number }>>(
    `/pms/product/update/${productUpdateInfo.id}`,
    productUpdateInfo
  );
};

export const createProduct = (productCreateInfo: ProductCreateInfo) => {
  return request.post<BaseRes<{ data: number }>>(
    '/pms/product/create',
    productCreateInfo
  );
};
