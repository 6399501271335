import { BaseRes, PageQuery } from 'types/base';
import {
  OmsOrder,
  OmsOrderDetail,
  QuotationForm,
  SellYourBagRecord,
  OmsOrderMultiplePay
} from 'types/oms';
import request from '.';

export const getSellYourBagList = (params: {
  current: number;
  size: number;
  productName: string;
}) => {
  return request.get<
    BaseRes<{
      total: number;
      records: SellYourBagRecord[];
    }>
  >('/oms/recycling/', {
    params,
  });
};

export const getSellYourBagDetail = (id: string) => {
  return request.get<BaseRes<SellYourBagRecord>>(`/oms/recycling/get/${id}`);
};

export const createQuotationForm = (quotationForm: QuotationForm) => {
  return request.post<BaseRes<number>>(
    '/oms/quotation-form/create',
    quotationForm
  );
};

export const getQuotationForm = (id: number) => {
  return request.get<BaseRes<QuotationForm>>(`/oms/quotation-form/get/${id}`);
};

export const updateQuotationForm = (quotationForm: QuotationForm) => {
  return request.post<BaseRes<number>>(
    '/oms/quotation-form/update',
    quotationForm
  );
};

export const deleteQuotationForm = (id: number) => {
  return request.post<BaseRes<number>>('/oms/quotation-form/update', {
    id,
    deletedStatus: 1,
  });
};

export type SearchQuotationFormDto = {
  pageSize: number;
  pageNum: number;
  startTime?: string;
  endTime?: string;
  keyword?: string;
};

export const searchQuotationForm = (data: SearchQuotationFormDto) => {
  return request.post<
    BaseRes<{
      list: QuotationForm[];
      total: number;
    }>
  >('/oms/quotation-form/search', data);
};

export type GetOrderListDto = {
  memberId?: string;
  status?: number;
  createTime?: string;
  createdFroms?: number[];
  receiverKeyword?: string;
} & PageQuery;

export const getOrderList = (data: GetOrderListDto) => {
  return request.post<
    BaseRes<{
      list: OmsOrder[];
      total: number;
    }>
  >('/oms/order/list', data, {
    params: {
      pageNum: data.pageNum,
      pageSize: data.pageSize,
    },
  });
};

export const getOmsOrderDetailById = (id: OmsOrder['id']) => {
  return request.get<BaseRes<OmsOrderDetail>>(`/oms/order/${id}`);
};

export const getOmsOrderMultiplePayList = (id: OmsOrder['id']) => {
  return request.get<BaseRes<OmsOrderMultiplePay[]>>(`/oms/orderMultiplePaySet/info/${id}`);
};


export const setOmsOrderMultiplePayList = (id: OmsOrder['id'],data: OmsOrderMultiplePay[]) => {
  return request.post(`/oms/orderMultiplePaySet/set/${id}`,data);
};

type OmsOrderReceiverInfoUpdateDto = {
  orderId: number;
  status: number;
  receiverName: string;
  receiverPhone: string;
  receiverPostCode: string;
  receiverDetailAddress: string;
  receiverProvince: string;
  receiverCity: string;
};

export const omsOrderReceiverInfoUpdate = (
  data: OmsOrderReceiverInfoUpdateDto
) => {
  return request.post('/oms/order/update/receiverInfo', data);
};

type OmsOrderAddOperationNoteDto = {
  id: number;
  note: string;
  status: number;
};

export const omsOrderAddOperationNote = (data: OmsOrderAddOperationNoteDto) => {
  return request.post('/oms/order/update/note', {}, { params: data });
};

type OmsOrderDeliverUpdateDto = {
  orderId: number;
  deliveryCompany: string;
  deliverySn: string;
}[];

export const omsOrderDeliverUpdate = (data: OmsOrderDeliverUpdateDto) => {
  return request.post('/oms/order/update/delivery', data);
};

export const omsOrderCompleteUpdate = (data: { id: number; note: string }) => {
  return request.post('/oms/order/update/finish', {}, { params: data });
};

export const omsOrderCancelUpdate = (data: { id: number; note: string }) => {
  return request.post('oms/order/update/cancel', {}, { params: data });
};

export const omsOrderOfflinePayAccept = (data: {
  orderPayId: string;
  note: string;
}) => {
  return request.post(
    '/oms/order/offlinePay/accept',
    {},
    {
      params: data,
    }
  );
};

export const omsOrderOfflinePayReject = (data: {
  orderPayId: string;
  note: string;
}) => {
  return request.post(
    '/oms/order/offlinePay/reject',
    {},
    {
      params: data,
    }
  );
};
