import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  message,
} from 'antd';
import { getCityListByCountryCode } from 'apis/home';
import { getMemberById, umsMemberCreate, umsMemberUpdate } from 'apis/ums';
import LOCALS from 'commons/locals';
import {
  GENDER_OPTION_LIST,
  SHOP_MAP,
  SHOP_OPTION_LIST,
  SOCIAL_MEDIA_MAP,
  SOCIAL_MEDIA_OPTION_LIST,
} from 'commons/options';
import dayjs, { Dayjs } from 'dayjs';
import i18n from 'i18n';
import { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { MallCity } from 'types/home';
import { UmsMember } from 'types/ums';

type MemberAddEditProps = {
  mode: 'add' | 'edit';
  memberId?: UmsMember['id'];
};

const MemberAddEdit = ({ memberId, mode }: MemberAddEditProps) => {
  const navigate = useNavigate();
  const [form] = Form.useForm<
    Omit<UmsMember, 'birthday'> & {
      birthday?: Dayjs;
    }
  >();
  const [country, setCountry] = useState('');
  const [cityList, setCityList] = useState<MallCity[]>([]);
  const { countryOptions, countryCodeOptions } =
    useAppSelector(selectGlobalInfo);

  useEffect(() => {
    if (!country) return;

    getCityListByCountryCode(country).then((res) => {
      setCityList(res.data.cityList || []);
    });
  }, [country]);

  useEffect(() => {
    if (mode === 'add') {
      form.setFieldsValue({
        gender: 0,
        countryCode: '81',
        socialName: SOCIAL_MEDIA_MAP.WHATSAPP,
        // TODO: 需要根据账号所属店铺区别初始化
        createSource: SHOP_MAP.WEBSITE,
      });
    }

    if (mode === 'edit' && memberId) {
      getMemberById(memberId).then(({ data }) => {
        setCountry(data.country);

        form.setFieldsValue({
          ...data,
          birthday: data.birthday ? dayjs(data.birthday) : undefined,
        });
      });
    }
  }, [form, memberId, mode]);

  const onFinish = () => {
    Modal.confirm({
      title: <Trans i18nKey={LOCALS.confirm_submit} />,
      onOk: async () => {
        const birthday = form.getFieldsValue().birthday
          ? form.getFieldsValue().birthday?.format('YYYY-MM-DD')
          : undefined;

        if (mode === 'add') {
          const data: Omit<UmsMember, 'id'> = {
            ...form.getFieldsValue(),
            birthday,
          };

          // TODO: 可能需要跳转订单创建页面
          await umsMemberCreate(data);

          message.success(i18n.t(LOCALS.successful_operation));
          onBack();
        }

        if (mode === 'edit' && memberId) {
          const data: UmsMember = {
            ...form.getFieldsValue(),
            id: memberId,
            birthday,
          };

          await umsMemberUpdate(data);

          message.success(i18n.t(LOCALS.successful_operation));
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      },
    });
  };

  const onBack = () => {
    navigate('/ums/member-list');
  };

  return (
    <div>
      <Form form={form} labelCol={{ xl: 4, sm: 6 }} onFinish={onFinish}>
        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="firstName"
              label={<Trans i18nKey={LOCALS.first_name} />}
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey={LOCALS.required_field} />,
                },
              ]}
            >
              <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastName"
              label={<Trans i18nKey={LOCALS.last_name} />}
              rules={[
                {
                  required: true,
                  message: <Trans i18nKey={LOCALS.required_field} />,
                },
              ]}
            >
              <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={18}>
          <Col span={12}>
            <Form.Item
              name="firstNameKatakana"
              label={<Trans i18nKey={LOCALS.first_name_katakana} />}
            >
              <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="lastNameKatakana"
              label={<Trans i18nKey={LOCALS.last_name_katakana} />}
            >
              <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          name="gender"
          rules={[
            {
              required: true,
              message: <Trans i18nKey={LOCALS.required_field} />,
            },
          ]}
          label={<Trans i18nKey={LOCALS.gender} />}
        >
          <Radio.Group>
            {GENDER_OPTION_LIST.map(({ value, label }) => {
              return (
                <Radio key={value} value={value}>
                  {label}
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          name="email"
          label={<Trans i18nKey={LOCALS.email} />}
          rules={[
            {
              required: true,
              message: <Trans i18nKey={LOCALS.required_field} />,
            },
            { type: 'email' },
          ]}
        >
          <Input
            disabled={mode === 'edit'}
            placeholder={i18n.t(LOCALS.please_enter) || ''}
          />
        </Form.Item>

        {mode === 'add' && (
          <Form.Item
            labelCol={{ xl: 2, sm: 3 }}
            name="password"
            label={<Trans i18nKey={LOCALS.password} />}
            rules={[
              {
                required: true,
                message: <Trans i18nKey={LOCALS.required_field} />,
              },
            ]}
            hasFeedback
          >
            <Input.Password placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>
        )}

        {mode === 'add' && (
          <Form.Item
            labelCol={{ xl: 2, sm: 3 }}
            name="repeatPassword"
            label={<Trans i18nKey={LOCALS.repeat_password} />}
            rules={[
              {
                required: true,
                message: <Trans i18nKey={LOCALS.required_field} />,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!'
                    )
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder={i18n.t(LOCALS.please_enter) || ''} />
          </Form.Item>
        )}

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          label={<Trans i18nKey={LOCALS.phone_number} />}
          required
          style={{ marginBottom: 0 }}
        >
          <Row gutter={18}>
            <Col span={6}>
              <Form.Item
                name="countryCode"
                rules={[
                  {
                    required: true,
                    message: <Trans i18nKey={LOCALS.required_field} />,
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(LOCALS.please_select)}
                  options={countryCodeOptions}
                />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: <Trans i18nKey={LOCALS.required_field} />,
                  },
                ]}
              >
                <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          label={<Trans i18nKey={LOCALS.birthday} />}
          name="birthday"
          rules={[
            {
              required: true,
              message: <Trans i18nKey={LOCALS.required_field} />,
            },
          ]}
        >
          <DatePicker allowClear={false} style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          label={<Trans i18nKey={LOCALS.country_region} />}
          name="country"
          rules={[
            {
              required: true,
              message: <Trans i18nKey={LOCALS.required_field} />,
            },
          ]}
        >
          <Select
            placeholder={i18n.t(LOCALS.please_select) || ''}
            onChange={(country) => {
              setCountry(country);
              form.setFieldValue('city', '');
            }}
            options={countryOptions}
          />
        </Form.Item>

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          label={<Trans i18nKey={LOCALS.state_province_city} />}
          name="city"
        >
          <Select
            placeholder={i18n.t(LOCALS.please_select) || ''}
            options={cityList.map(({ name, id }) => {
              return {
                value: `${id}`,
                label: name,
              };
            })}
          />
        </Form.Item>

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          label={<Trans i18nKey={LOCALS.detail_address} />}
          name="detailAddress"
        >
          <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          label={<Trans i18nKey={LOCALS.social_media} />}
          name="socialName"
        >
          <Select
            options={SOCIAL_MEDIA_OPTION_LIST}
            placeholder={i18n.t(LOCALS.please_select) || ''}
          />
        </Form.Item>

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          label={<Trans i18nKey={LOCALS.social_handle} />}
          name="socialAccount"
        >
          <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          label={<Trans i18nKey={LOCALS.created_from} />}
          name="createSource"
          rules={[
            {
              required: true,
              message: <Trans i18nKey={LOCALS.required_field} />,
            },
          ]}
        >
          <Radio.Group options={SHOP_OPTION_LIST} />
        </Form.Item>

        <Form.Item
          labelCol={{ xl: 2, sm: 3 }}
          label={<Trans i18nKey={LOCALS.note} />}
          name="remark"
        >
          <Input.TextArea placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Space>
              <Button onClick={onBack}>
                <Trans i18nKey={LOCALS.back} />
              </Button>
              <Button type="primary" htmlType="submit">
                <Trans i18nKey={LOCALS.submit} />
              </Button>
            </Space>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default MemberAddEdit;
