import {
  Button,
  Cascader,
  Form,
  Input,
  Select,
  Space,
  Switch,
  Table,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getProductList } from 'apis/pms';
import useIsMobile from 'commons/hooks/useIsMobile';
import usePagination from 'commons/hooks/usePagination';
import LOCALS from 'commons/locals';
import {
  PRODUCT_CREATED_FROM_OPTION_LIST,
  PROMOTION_TYPE_MAP,
  PUBLISH_STATUS_OPTION_LIST,
  STOCK_PLACE_OPTION_LIST,
  findLabelByValue,
} from 'commons/options';
import i18n from 'i18n';
import { useCallback, useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { PageQuery } from 'types/base';
import { PmsProduct } from 'types/pms';
import formatTime from 'utils/formatTime';

type SearchFormData = {
  productCategoryIds?: number[];
  keyword?: string;
  stockPlace?: string;
  isFilterPromotion?: boolean;
  publishStatus?: number;
};

const ProductList = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [form] = Form.useForm<SearchFormData>();
  const { productCategoryCascaderOptions } = useAppSelector(selectGlobalInfo);
  const {
    loading,
    setLoading,
    pageNum,
    setPageNum,
    pageSize,
    setPageSize,
    total,
    setTotal,
    dataSource,
    setDataSource,
  } = usePagination<PmsProduct>();

  const getDataSource = useCallback(
    async ({ pageNum, pageSize }: PageQuery) => {
      const {
        productCategoryIds,
        keyword,
        publishStatus,
        stockPlace,
        isFilterPromotion,
      } = form.getFieldsValue();

      try {
        setLoading(true);

        const {
          data: { list, total },
        } = await getProductList({
          pageNum,
          pageSize,
          keyword,
          stockPlace,
          isFilterPromotion,
          publishStatus,
          productCategoryId: productCategoryIds
            ? productCategoryIds[productCategoryIds.length - 1]
            : undefined,
          // 这个接口默认返回的是转换为日元的价格，加上这个参数并且设置为 0，可以返回商品本来的价格和汇率
          transformPriceToJpyFlag: 0,
        });

        setDataSource(list);
        setTotal(total);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [form, setDataSource, setLoading, setTotal]
  );

  useEffect(() => {
    getDataSource({ pageNum: 1, pageSize: 10 });
  }, [getDataSource]);

  const onFinish = async () => {
    setPageNum(1);
    getDataSource({ pageNum: 1, pageSize });
  };

  const onReset = () => {
    form.resetFields();
    onFinish();
  };

  const onClickAdd = () => {
    navigate('/pms/product-add');
  };

  const onViewDetails = useCallback(
    (id: PmsProduct['id']) => {
      navigate(`/pms/product-view/${id}`);
    },
    [navigate]
  );

  const onEdit = useCallback(
    (id: PmsProduct['id']) => {
      navigate(`/pms/product-edit/${id}`);
    },
    [navigate]
  );

  const columns: ColumnsType<PmsProduct> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.product_sn}></Trans>,
        dataIndex: 'productSn',
        key: 'productSn',
        render: (productSn: string, product: PmsProduct) => {
          return (
            <div>
              <p>{product.id}</p>
              <p>{productSn}</p>
            </div>
          );
        },
      },
      {
        dataIndex: 'pic',
        key: 'pic',
        title: <Trans i18nKey={LOCALS.product_pictures} />,
        render: (pic: string) => {
          if (!pic) return <span>-</span>;

          return <img src={pic} width={100} height={100} alt={pic} />;
        },
      },
      {
        dataIndex: 'name',
        key: 'name',
        title: <Trans i18nKey={LOCALS.product_name} />,
        render: (name: string, product: PmsProduct) => {
          return (
            <div>
              <p>{name}</p>
              {product.brandName && (
                <p>
                  <Trans i18nKey={LOCALS.brand} />：{product.brandName}
                </p>
              )}
            </div>
          );
        },
      },
      {
        key: 'publishStatus',
        title: (
          <div>
            <Trans i18nKey={LOCALS.publish_status} /> /{' '}
            <Trans i18nKey={LOCALS.stock} />
          </div>
        ),
        render: ({ publishStatus, stock }: PmsProduct) => {
          return (
            <div>
              {findLabelByValue(publishStatus, PUBLISH_STATUS_OPTION_LIST)} /{' '}
              {stock}
            </div>
          );
        },
      },
      {
        dataIndex: 'price',
        key: 'price',
        title: <Trans i18nKey={LOCALS.price} />,
        render: (price: number, product: PmsProduct) => {
          const { promotionType } = product;

          if (promotionType === PROMOTION_TYPE_MAP.SPECIAL) {
            return (
              <div>
                <p
                  style={{
                    textDecoration: 'line-through',
                  }}
                >
                  {product.currency} {product.originalPrice.toLocaleString()}
                </p>
                <p
                  style={{
                    color: 'var(--color-danger)',
                  }}
                >
                  {product.currency} {product.price?.toLocaleString() || '-'}
                </p>
              </div>
            );
          }

          return (
            <span>
              {product.currency} {product.price?.toLocaleString() || '-'}
            </span>
          );
        },
      },
      {
        dataIndex: 'stockPlace',
        key: 'stockPlace',
        title: <Trans i18nKey={LOCALS.stock_place} />,
        render: (stockPlace) => {
          return findLabelByValue(stockPlace, STOCK_PLACE_OPTION_LIST);
        },
      },
      {
        dataIndex: 'createdFrom',
        key: 'createdFrom',
        title: <Trans i18nKey={LOCALS.created_from} />,
        render: (createdFrom) => {
          return findLabelByValue(
            createdFrom,
            PRODUCT_CREATED_FROM_OPTION_LIST
          );
        },
      },
      {
        dataIndex: 'createdTime',
        key: 'createdTime',
        title: <Trans i18nKey={LOCALS.created_time} />,
        render: (createdTime: PmsProduct['createdTime']) => {
          return formatTime(createdTime);
        },
      },
      {
        key: 'options',
        title: <Trans i18nKey={LOCALS.options} />,
        render: ({ id }: PmsProduct) => {
          return (
            <div>
              <Button
                type="link"
                onClick={() => {
                  onViewDetails(id);
                }}
              >
                <Trans i18nKey={LOCALS.details} />
              </Button>

              <Button
                type="link"
                onClick={() => {
                  onEdit(id);
                }}
              >
                <Trans i18nKey={LOCALS.edit} />
              </Button>
            </div>
          );
        },
      },
    ];
  }, [onEdit, onViewDetails]);

  return (
    <div>
      <Form
        form={form}
        layout={isMobile ? 'vertical' : 'inline'}
        onFinish={onFinish}
      >
        <Form.Item
          name="productCategoryIds"
          label={<Trans i18nKey={LOCALS.product_category}></Trans>}
        >
          <Cascader
            changeOnSelect
            placeholder={i18n.t(LOCALS.please_select) || ''}
            style={{ minWidth: 120 }}
            options={productCategoryCascaderOptions}
          />
        </Form.Item>
        <Form.Item
          name="keyword"
          label={<Trans i18nKey={LOCALS.keyword}></Trans>}
        >
          <Input.TextArea placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item
          name="publishStatus"
          label={<Trans i18nKey={LOCALS.publish_status}></Trans>}
        >
          <Select
            style={{ minWidth: 120 }}
            placeholder={i18n.t(LOCALS.please_select) || ''}
            options={PUBLISH_STATUS_OPTION_LIST}
          ></Select>
        </Form.Item>

        <Form.Item
          name="stockPlace"
          label={<Trans i18nKey={LOCALS.stock_place} />}
        >
          <Select
            style={{ minWidth: 120 }}
            placeholder={i18n.t(LOCALS.please_select) || ''}
            options={STOCK_PLACE_OPTION_LIST}
          ></Select>
        </Form.Item>

        <Form.Item
          name="isFilterPromotion"
          valuePropName="checked"
          label={<Trans i18nKey={LOCALS.on_sale} />}
        >
          <Switch></Switch>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              <Trans i18nKey={LOCALS.search} />
            </Button>

            <Button htmlType="button" onClick={onReset}>
              <Trans i18nKey={LOCALS.reset} />
            </Button>
            <Button onClick={onClickAdd}>
              <Trans i18nKey={LOCALS.add} />
            </Button>
          </Space>
        </Form.Item>
      </Form>

      <Table
        pagination={{
          total,
          pageSize,
          current: pageNum,
          onChange: (page, pageSize) => {
            setPageNum(page);
            setPageSize(pageSize);
            getDataSource({ pageNum: page, pageSize });
          },
        }}
        loading={loading}
        rowKey={'id'}
        style={{
          marginTop: 12,
        }}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default ProductList;
