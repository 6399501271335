import { Button, Form, Input, message } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { getMemberList } from 'apis/ums';
import LOCALS from 'commons/locals';
import { useCallback, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { UmsMember } from 'types/ums';
import styles from './index.module.scss';
import i18n from 'i18n';

type Props = {
  onUmsMemberChange?: (umsMember: UmsMember | undefined) => void;
};

const SearchMember = ({ onUmsMemberChange }: Props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [umsMember, setUmsMember] = useState<UmsMember>();

  const onFinish = useCallback(async () => {
    const data = form.getFieldsValue();
    setLoading(true);

    try {
      const {
        data: { list },
      } = await getMemberList(data);

      const umsMember = list[0];

      if (!umsMember) {
        message.warning(i18n.t(LOCALS.XeaiVflJZJ));
        return;
      }

      setUmsMember(umsMember);
      onUmsMemberChange && onUmsMemberChange(umsMember);
      form.resetFields();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [onUmsMemberChange, form]);

  const columns: ColumnsType<UmsMember> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.name} />,
        key: 'name',
        render: ({ firstName, lastName, nickname, id, email }: UmsMember) => {
          let name = '';

          if (firstName && lastName) {
            name = `${firstName} ${lastName}`;
          } else {
            name = nickname || email;
          }

          return (
            <a target="__blank" href={`/ums/member-view/${id}`}>
              {name}
            </a>
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.phone_number} />,
        key: 'phone',
        render: ({ phone, countryCode }: UmsMember) => {
          if (!phone) return '-';

          if (countryCode) {
            return `+${countryCode} ${phone}`;
          }

          return phone;
        },
      },
      {
        title: <Trans i18nKey={LOCALS.points} />,
        key: 'points',
        render: ({ integration }: UmsMember) => {
          return integration || 0;
        },
      },
      {
        title: <Trans i18nKey={LOCALS.options} />,
        key: 'options',
        render: () => {
          return (
            <Button
              danger
              type="link"
              onClick={() => {
                setUmsMember(undefined);
                onUmsMemberChange && onUmsMemberChange(undefined);
              }}
            >
              <Trans i18nKey={LOCALS.delete} />
            </Button>
          );
        },
      },
    ];
  }, [onUmsMemberChange]);

  return (
    <div>
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.member_info} />
      </div>

      <Form
        form={form}
        layout="inline"
        style={{
          marginBottom: 12,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          label={<Trans i18nKey={LOCALS.email} />}
          name="keyword"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            <Trans i18nKey={LOCALS.search} />
          </Button>
        </Form.Item>
      </Form>

      <Table
        loading={loading}
        pagination={false}
        dataSource={umsMember ? [umsMember] : []}
        columns={columns}
        rowKey={'id'}
      />
    </div>
  );
};

export default SearchMember;
