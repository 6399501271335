import { OmsOrderDetail } from 'types/oms';
import { Trans } from 'react-i18next';
import LOCALS from 'commons/locals';
import { Descriptions } from 'antd';

type OmsOrderPaymentInfoProps = {
  omsOrderDetail: OmsOrderDetail;
};

const OmsOrderPaymentInfo = ({
  omsOrderDetail: {
    totalAmount,
    payAmount,
    integrationAmount,
    promotionAmount,
  },
}: OmsOrderPaymentInfoProps) => {
  return (
    <div>
      {/* TODO: 展示实际币种的订单总金额和实际支付金额 */}
      <Descriptions title={<Trans i18nKey={LOCALS.payment_info} />} bordered>
        <Descriptions.Item
          label={<Trans i18nKey={LOCALS.order_total_amount} />}
        >
          ￥{totalAmount.toLocaleString()}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.amount_to_be_paid} />}>
          ￥{payAmount.toLocaleString()}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.points_deduction} />}>
          -￥{integrationAmount.toLocaleString()}
        </Descriptions.Item>

        <Descriptions.Item label={<Trans i18nKey={LOCALS.discount_amounts} />}>
          -￥{promotionAmount.toLocaleString()}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default OmsOrderPaymentInfo;
