import { Modal, Upload } from 'antd';
import { getLocalStorageToken } from 'commons';
import styles from './index.module.scss';
import { s3UploadUrl } from 'apis/cms';
import { PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import useContextMenu from 'commons/hooks/useContextMenu';
import ContextMenu from 'components/context-menu';
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from 'react-sortable-hoc';

type ImageUploaderProps = {
  // 单张图片还是图片列表
  mode?: 'single' | 'multiple';
  // 图片 src 列表
  imgList: string[];
  // 回调函数
  onChange: (list: ImageUploaderProps['imgList']) => void;
};

const ImageUploader = ({ mode, imgList, onChange }: ImageUploaderProps) => {
  const [visible, setVisible] = useState(false);
  const [picToView, setPicToView] = useState('');
  const [picToDelete, setPicToDelete] = useState('');
  const { clicked, setClicked, points, setPoints } = useContextMenu();

  const onContextMenu = (e: React.MouseEvent, src: string) => {
    e.preventDefault();
    setPicToView(src);
    setPicToDelete(src);
    setPoints({
      x: e.clientX,
      y: e.clientY,
    });
    setClicked(true);
  };

  const onPicView = () => {
    setVisible(true);
  };

  const onDeletePic = () => {
    if (!picToDelete) return;
    onChange(imgList.filter((src) => src !== picToDelete));
  };

  const SortableItem = SortableElement(({ value }: { value: string }) => (
    <img
      className={styles.imageItem}
      onClick={() => {
        // 这里非常奇怪，只在移动端可以触发
        setPicToView(value);
        setVisible(true);
      }}
      onContextMenu={(e) => {
        onContextMenu(e, value);
      }}
      key={value}
      src={value}
      alt={value}
    />
  ));

  const SortableList = SortableContainer(
    ({ items }: { items: ImageUploaderProps['imgList'] }) => {
      return (
        <div>
          {items.map((value, index) => (
            // @ts-ignore
            <SortableItem key={`item-${value}`} index={index} value={value} />
          ))}
        </div>
      );
    }
  );

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    onChange(arrayMove(imgList, oldIndex, newIndex));
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {
        <Modal
          width="50%"
          footer={null}
          open={visible}
          onCancel={() => {
            setVisible(false);
            setPicToView('');
          }}
        >
          <img style={{ width: '100%' }} src={picToView} alt={picToView} />
        </Modal>
      }
      {clicked && (
        <ContextMenu
          onDelete={onDeletePic}
          onView={onPicView}
          top={points.y}
          left={points.x}
        ></ContextMenu>
      )}

      <SortableList
        // @ts-ignore
        items={imgList}
        pressDelay={1}
        onSortEnd={onSortEnd}
        axis="xy"
      />

      <Upload
        data={{
          loginToken: getLocalStorageToken(),
        }}
        className={styles.imageUploader}
        listType="picture-card"
        showUploadList={false}
        action={s3UploadUrl}
        onChange={(e) => {
          const url = e.file?.response?.data?.url;
          if (url) {
            if (mode === 'single') {
              onChange([url]);
            } else {
              onChange([...imgList, url]);
            }
          }
        }}
      >
        <PlusOutlined />
      </Upload>
    </div>
  );
};

export default ImageUploader;
