import { Trans } from 'react-i18next';
import styles from './index.module.scss';
import LOCALS from 'commons/locals';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PmsProduct } from 'types/pms';
import { Button, Form, Input, Table, message } from 'antd';
import { getProductList } from 'apis/pms';
import i18n from 'i18n';
import { ColumnsType } from 'antd/es/table';
import {
  PROMOTION_TYPE_MAP,
  PUBLISH_STATUS_OPTION_LIST,
  STOCK_PLACE_OPTION_LIST,
  findLabelByValue,
} from 'commons/options';

type Props = {
  onPmsProductListChange?: (pmsProductList: PmsProduct[]) => void;
};

const SearchProduct = ({ onPmsProductListChange }: Props) => {
  const [pmsProductList, setPmsProductList] = useState<PmsProduct[]>([]);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onPmsProductListChange && onPmsProductListChange(pmsProductList);
  }, [onPmsProductListChange, pmsProductList]);

  const onFinish = useCallback(async () => {
    const data = await form.validateFields();
    setLoading(true);

    try {
      const {
        data: { list },
      } = await getProductList({
        pageNum: 1,
        pageSize: 10,
        transformPriceToJpyFlag: 0,
        ...data,
      });

      const pmsProduct = list[0];

      if (!pmsProduct) {
        message.warning(i18n.t(LOCALS.zMANYPXpGr));
        return;
      }

      setPmsProductList((pmsProductList) => {
        const target = pmsProductList.find(({ id }) => {
          return id === pmsProduct.id;
        });

        if (!target) {
          form.resetFields();
          return [...pmsProductList, pmsProduct];
        } else {
          return pmsProductList;
        }
      });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [form]);

  const columns: ColumnsType<PmsProduct> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.product_sn}></Trans>,
        key: 'productSn',
        render: ({ productSn, id }: PmsProduct) => {
          return (
            <a target="__blank" href={`/pms/product-view/${id}`}>
              {productSn}
            </a>
          );
        },
      },
      {
        dataIndex: 'pic',
        key: 'pic',
        title: <Trans i18nKey={LOCALS.product_pictures} />,
        render: (pic: string) => {
          if (!pic) return <span>-</span>;

          return <img src={pic} width={100} height={100} alt={pic} />;
        },
      },
      {
        dataIndex: 'name',
        key: 'name',
        title: <Trans i18nKey={LOCALS.product_name} />,
      },
      {
        dataIndex: 'price',
        key: 'price',
        title: <Trans i18nKey={LOCALS.price} />,
        render: (price: number, product: PmsProduct) => {
          const { promotionType } = product;

          if (promotionType === PROMOTION_TYPE_MAP.SPECIAL) {
            return (
              <div>
                <p
                  style={{
                    textDecoration: 'line-through',
                  }}
                >
                  {product.currency} {product.originalPrice.toLocaleString()}
                </p>
                <p
                  style={{
                    color: 'var(--color-danger)',
                  }}
                >
                  {product.currency} {product.price?.toLocaleString() || '-'}
                </p>
              </div>
            );
          }

          return (
            <span>
              {product.currency} {product.price?.toLocaleString() || '-'}
            </span>
          );
        },
      },
      {
        dataIndex: 'stockPlace',
        key: 'stockPlace',
        title: <Trans i18nKey={LOCALS.stock_place} />,
        render: (stockPlace) => {
          return findLabelByValue(stockPlace, STOCK_PLACE_OPTION_LIST);
        },
      },
      {
        key: 'publishStatus',
        title: (
          <div>
            <Trans i18nKey={LOCALS.publish_status} /> /{' '}
            <Trans i18nKey={LOCALS.stock} />
          </div>
        ),
        render: ({ publishStatus, stock }: PmsProduct) => {
          return (
            <div>
              {findLabelByValue(publishStatus, PUBLISH_STATUS_OPTION_LIST)} /{' '}
              {stock}
            </div>
          );
        },
      },
      {
        key: 'options',
        title: <Trans i18nKey={LOCALS.options} />,
        render: (pmsProduct: PmsProduct) => {
          return (
            <div>
              <Button
                type="link"
                danger
                onClick={() => {
                  setPmsProductList((pmsProductList) => {
                    return pmsProductList.filter(({ id }) => {
                      return id !== pmsProduct.id;
                    });
                  });
                }}
              >
                <Trans i18nKey={LOCALS.delete} />
              </Button>
            </div>
          );
        },
      },
    ];
  }, []);

  return (
    <div>
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.product_info} />
      </div>

      <Form
        form={form}
        layout="inline"
        style={{
          marginBottom: 12,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          name="productSn"
          label={<Trans i18nKey={LOCALS.product_sn} />}
          rules={[{ required: true }]}
        >
          <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            <Trans i18nKey={LOCALS.search} />
          </Button>
        </Form.Item>
      </Form>

      <Table
        loading={loading}
        pagination={false}
        rowKey={'id'}
        columns={columns}
        dataSource={pmsProductList}
      />
    </div>
  );
};

export default SearchProduct;
