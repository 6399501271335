import { OmsOrderDetail, OmsOrderPayment } from 'types/oms';
import styles from './index.module.scss';
import LOCALS from 'commons/locals';
import { Trans } from 'react-i18next';
import Table, { ColumnsType } from 'antd/es/table';
import { OFFLINE_PAY_TYPE_LIST } from 'commons';
import { useMemo } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { findLabelByValue } from 'commons/options';
import formatTime from 'utils/formatTime';

type OnlinePaymentRecordProps = {
  omsOrderDetail: OmsOrderDetail;
};

const OnlinePaymentRecord = ({
  omsOrderDetail: { payList },
}: OnlinePaymentRecordProps) => {
  const { payStatusOptions } = useAppSelector(selectGlobalInfo);

  const columns: ColumnsType<OmsOrderPayment> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.payment_channel}></Trans>,
        dataIndex: 'payType',
        key: 'payType',
      },
      {
        title: <Trans i18nKey={LOCALS.pay_amount}></Trans>,
        dataIndex: 'payAmount',
        key: 'payAmount',
        render: (payAmount: OmsOrderPayment['payAmount']) => {
          return (payAmount || '').toLocaleString();
        },
      },
      {
        title: <Trans i18nKey={LOCALS.currency}></Trans>,
        dataIndex: 'payCurrency',
        key: 'payCurrency',
        render: (payCurrency: OmsOrderPayment['payCurrency']) => {
          return payCurrency || '-';
        },
      },
      {
        title: <Trans i18nKey={LOCALS.payment_status}></Trans>,
        dataIndex: 'payStatus',
        key: 'payStatus',
        render: (payStatus: OmsOrderPayment['payStatus']) => {
          return payStatus
            ? findLabelByValue(+payStatus, payStatusOptions)
            : '-';
        },
      },
      {
        title: <Trans i18nKey={LOCALS.payment_time}></Trans>,
        dataIndex: 'payTime',
        key: 'payTime',
        render: (payTime: OmsOrderPayment['payTime']) => {
          return formatTime(payTime);
        },
      },
    ];
  }, [payStatusOptions]);

  return (
    <div>
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.online_payment_record} />
      </div>

      <Table
        rowKey={'id'}
        pagination={false}
        columns={columns}
        dataSource={
          payList
            ? payList.filter(({ payType }) => {
                return !OFFLINE_PAY_TYPE_LIST.includes(payType);
              })
            : []
        }
      />
    </div>
  );
};

export default OnlinePaymentRecord;
