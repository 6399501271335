import { Descriptions, Tag } from 'antd';
import LOCALS from 'commons/locals';
import {
  ORDER_CREATED_FROM_OPTION_LIST,
  ORDER_STATUS_ANTD_TAG_COLOR_MAP,
  findLabelByValue,
} from 'commons/options';
import LinkButton from 'components/link-button';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { OmsOrderDetail } from 'types/oms';

type OrderBaseInfoProps = {
  omsOrderDetail: OmsOrderDetail;
};

const OrderBaseInfo = ({ omsOrderDetail }: OrderBaseInfoProps) => {
  const {
    status,
    orderSn,
    memberUsername,
    createdFrom,
    deliveryCompany,
    deliverySn,
    integration,
    memberId,
    multiplePayStatus
  } = omsOrderDetail;
  const { orderStatusOptions } = useAppSelector(selectGlobalInfo);

  return (
    <Descriptions title={<Trans i18nKey={LOCALS.basic_info} />} bordered>
      <Descriptions.Item label={<Trans i18nKey={LOCALS.order_status} />}>
        <Tag color={ORDER_STATUS_ANTD_TAG_COLOR_MAP[status]}>
          {findLabelByValue(status, orderStatusOptions)}
        </Tag>
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.order_sn} />}>
        {orderSn}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.member_account} />}>
        <LinkButton href={`/ums/member-view/${memberId}`}>
          {memberUsername}
        </LinkButton>
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.created_from} />}>
        {findLabelByValue(createdFrom, ORDER_CREATED_FROM_OPTION_LIST)}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.delivery_method} />}>
        {deliveryCompany || '-'}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.shipment_number} />}>
        {deliverySn || '-'}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.points_for_order} />}>
        {integration || 0}
      </Descriptions.Item>
      <Descriptions.Item label={<Trans i18nKey={LOCALS.MultiplePayList} />}>
        {multiplePayStatus ? <Trans i18nKey={LOCALS.yes}/> : '-'}
      </Descriptions.Item>
    </Descriptions>
  );
};

export default OrderBaseInfo;
