import { BaseRes, PageQuery } from 'types/base';
import { SysDict, SysUser } from 'types/sys';
import request from '.';

export const getSysUserSimpleList = () => {
  return request.get<BaseRes<SysUser[]>>('sys/user/simpleList');
};

type GetSysUserListDto = {
  keyword?: string;
} & PageQuery;

export const getSysUserList = (data: GetSysUserListDto) => {
  return request.get<BaseRes<{ list: SysUser[]; total: number }>>(
    '/sys/user/list',
    { params: data }
  );
};

type SysUserRegisterDto = {
  username: SysUser['username'];
  nickName: SysUser['nickName'];
  password: SysUser['password'];
  email: SysUser['email'];
  note: SysUser['note'];
  shop: SysUser['shop'];
};

export const sysUserRegister = (data: SysUserRegisterDto) => {
  return request.post('/sys/user/register', data);
};

export const sysUserUpdate = (
  data: SysUserRegisterDto & {
    id: SysUser['id'];
  }
) => {
  return request.post(`/sys/user/update/${data.id}`, data);
};

export const getSysDictList = () => {
  return request.get<
    BaseRes<{
      records: SysDict[];
    }>
  >('sys/dict/list', {
    params: {
      pageNum: 1,
      pageSize: 9999,
    },
  });
};
