import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import XiaomaLayout from 'components/xiaoma-layout';
import Home from 'pages/home';
import ProductList from 'pages/product/list';
import Login from 'pages/login';
import {
  getLocalStorageFirstPage,
  getLocalStorageLanguage,
  LOGIN_PAGE,
} from 'commons';
import { useAppDispatch } from 'store/hooks';
import {
  setAccessorySelectOptions,
  setCollectionSelectOptionsMap,
  setColorSelectOptions,
  setCountryCodeOptions,
  setCountryOptions,
  setHardwareSelectOptions,
  setHueSelectOptions,
  setLanguage,
  setMaterialCascaderOptionMap,
  setOrderStatusOptions,
  setPayStatusOptions,
  setProductCategoryCascaderOptions,
  setRankSelectOptions,
  setStampSelectOptions,
  setTypeSelectOptions,
} from 'store/slices/globalInfoSlice';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'store/slices/userInfoSlice';
import ProductAddEdit from 'pages/product/add-edit';
import SellYourBag from 'pages/rrs/sell-your-bag';
import QuotationFormList from 'pages/rrs/quotation-form/list';
import SellYourBagEdit from 'pages/rrs/sell-your-bag-edit';
import QuotationFormAddEdit from 'pages/rrs/quotation-form/add-edit';
import CashRegister from 'pages/prints/cash-register';
import { ProductAttributeCodeNames } from 'types/pms';
import useProductAttributeCodeList from 'commons/hooks/useProductAttributeCodeList';
import { groupBy } from 'lodash-es';
import buildTreeFromProductAttributeCodeList from 'utils/buildTreeFromProductAttributeCodeList';
import useProductCategoryTree from 'commons/hooks/useProductCategoryTree';
import MemberList from 'pages/ums/member-list';
import MemberView from 'pages/ums/member-view';
import { getSysDictList } from 'apis/sys';
import { LANGUAGE_MAP } from 'commons/options';
import MemberEdit from 'pages/ums/member-edit';
import MemberAdd from 'pages/ums/member-add';
import OrderList from 'pages/oms/order-list';
import OrderView from 'pages/oms/order-view';
import { useCountryList } from 'apis/home';
import AdminList from 'pages/sys/admin-list';
import OrderCreate from 'pages/oms/order-create';

const names = [
  ProductAttributeCodeNames.COLOR,
  ProductAttributeCodeNames.MATERIAL,
  ProductAttributeCodeNames.STAMP,
  ProductAttributeCodeNames.RANK,
  ProductAttributeCodeNames.TYPE,
  ProductAttributeCodeNames.HUE,
  ProductAttributeCodeNames.HARDWARE,
  ProductAttributeCodeNames.ACCESSORIES,
  ProductAttributeCodeNames.COLLECTIONS,
];

function App() {
  const dispatch = useAppDispatch();
  const userInfo = useSelector(selectUserInfo);
  const [
    colorAttributeCodeList,
    materialAttributeCodeList,
    stampAttributeCodeList,
    rankAttributeCodeList,
    typeAttributeCodeList,
    hueAttributeCodeList,
    hardwareAttributeCodeList,
    accessoryAttributeCodeList,
    collectionAttributeCodeList,
  ] = useProductAttributeCodeList(names);
  const productCategoryCascaderOptions = useProductCategoryTree();
  const { countryList } = useCountryList();

  useEffect(() => {
    dispatch(setLanguage(getLocalStorageLanguage()));
    dispatch(setColorSelectOptions(colorAttributeCodeList));
    dispatch(setStampSelectOptions(stampAttributeCodeList));
    dispatch(setRankSelectOptions(rankAttributeCodeList));
    dispatch(setProductCategoryCascaderOptions(productCategoryCascaderOptions));
    dispatch(setTypeSelectOptions(typeAttributeCodeList));
    dispatch(setHueSelectOptions(hueAttributeCodeList));
    dispatch(setHardwareSelectOptions(hardwareAttributeCodeList));
    dispatch(setAccessorySelectOptions(accessoryAttributeCodeList));

    const materialProductAttributeCodeListMap = groupBy(
      materialAttributeCodeList,
      (i) => i.categoryId
    );
    const materialCascaderOptionMap: any = {};
    Object.keys(materialProductAttributeCodeListMap).forEach((key) => {
      materialCascaderOptionMap[key] = buildTreeFromProductAttributeCodeList(
        materialProductAttributeCodeListMap[key]
      );
    });
    dispatch(setMaterialCascaderOptionMap(materialCascaderOptionMap));

    dispatch(
      setCollectionSelectOptionsMap(
        groupBy(collectionAttributeCodeList, (i) => i.categoryId)
      )
    );

    getSysDictList().then(({ data: { records } }) => {
      const dictTypeList = ['orderStatus', 'payStatus'];

      const [orderStatusOptions, payStatusOptions] = dictTypeList.map(
        (dictType) => {
          const temp = records.find(({ type }) => type === dictType);
          if (!temp) return [];

          return temp.valueList.map(({ en, ja, zh, value }) => {
            const language = getLocalStorageLanguage();
            const labelMap = {
              [LANGUAGE_MAP.EN]: en,
              [LANGUAGE_MAP.JA]: ja,
              [LANGUAGE_MAP.ZH_CN]: zh,
            };

            return {
              value: +value,
              label: labelMap[language],
            };
          });
        }
      );

      dispatch(setOrderStatusOptions(orderStatusOptions));
      dispatch(setPayStatusOptions(payStatusOptions));
    });

    dispatch(
      setCountryOptions(
        countryList.map(({ code, name }) => {
          return {
            value: code,
            label: name,
          };
        })
      )
    );

    dispatch(
      setCountryCodeOptions(
        countryList.map(({ areaCode, name }) => {
          return {
            value: areaCode,
            label: `+ ${areaCode} ${name}`,
          };
        })
      )
    );
  }, [
    colorAttributeCodeList,
    materialAttributeCodeList,
    stampAttributeCodeList,
    rankAttributeCodeList,
    productCategoryCascaderOptions,
    typeAttributeCodeList,
    hueAttributeCodeList,
    hardwareAttributeCodeList,
    accessoryAttributeCodeList,
    collectionAttributeCodeList,
    dispatch,
    countryList,
  ]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<XiaomaLayout />}>
            <Route path="home" element={<Home />} />

            <Route path="pms/product" element={<ProductList />} />
            <Route
              path="pms/product-add"
              element={<ProductAddEdit key="add" mode="add" />}
            />
            <Route
              path="pms/product-view/:id"
              element={<ProductAddEdit key="view" mode="view" />}
            />
            <Route
              path="pms/product-edit/:id"
              element={<ProductAddEdit key="edit" mode="edit" />}
            />

            <Route path="oms/order-list" element={<OrderList />} />
            <Route path="oms/order-view/:id" element={<OrderView />} />
            <Route path="oms/order-create" element={<OrderCreate />} />

            <Route path="ums/member-list" element={<MemberList />} />
            <Route path="ums/member-view/:id" element={<MemberView />} />
            <Route path="ums/member-add" element={<MemberAdd />} />
            <Route path="ums/member-edit/:id" element={<MemberEdit />} />

            <Route path="rrs/sell-your-bag" element={<SellYourBag />} />
            <Route
              path="rrs/sell-your-bag-edit/:id"
              element={<SellYourBagEdit />}
            />

            <Route path="rrs/quotation-form" element={<QuotationFormList />} />
            <Route
              path="rrs/quotation-form-add"
              element={<QuotationFormAddEdit />}
            />
            <Route
              path="rrs/quotation-form-detail/:id"
              element={<QuotationFormAddEdit />}
            />
            <Route
              path="rrs/quotation-form-edit/:id"
              element={<QuotationFormAddEdit isEdit={true} />}
            />

            <Route path="sys/admin-list" element={<AdminList />}></Route>

            <Route
              path="*"
              element={
                <Navigate to={getLocalStorageFirstPage(userInfo.username)} />
              }
            />
          </Route>
          <Route
            path="/prints/cash-register"
            element={<CashRegister />}
          ></Route>
          <Route path={LOGIN_PAGE} element={<Login />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
