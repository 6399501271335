import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { OmsOrderDetail, OmsOrderItem } from 'types/oms';
import styles from './index.module.scss';
import LOCALS from 'commons/locals';
import { Trans } from 'react-i18next';
import LinkButton from 'components/link-button';

type OrderItemListProps = {
  omsOrderDetail: OmsOrderDetail;
};

const OrderItemList = ({
  omsOrderDetail: { orderItemList },
}: OrderItemListProps) => {
  const columns: ColumnsType<OmsOrderItem> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.product_sn} />,
        dataIndex: 'productSn',
        key: 'productSn',
        render: (
          productSn: OmsOrderItem['productSn'],
          omsOrderItem: OmsOrderItem
        ) => {
          return (
            <LinkButton href={`/pms/product-view/${omsOrderItem.productId}`}>
              {productSn}
            </LinkButton>
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.product_pictures} />,
        dataIndex: 'productPic',
        key: 'productPic',
        render: (productPic: OmsOrderItem['productPic']) => {
          return productPic ? (
            <img width={100} src={productPic} alt={productPic} />
          ) : (
            '-'
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.product_name} />,
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: <Trans i18nKey={LOCALS.price} />,
        dataIndex: 'productPrice',
        key: 'productPrice',
        render: (productPic: OmsOrderItem['productPrice']) => {
          return `JPY ${productPic.toLocaleString()}`;
        },
      },
    ];
  }, []);

  return (
    <div>
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.product_info} />
      </div>
      <Table
        pagination={false}
        rowKey={'id'}
        dataSource={orderItemList}
        columns={columns}
      ></Table>
    </div>
  );
};

export default OrderItemList;
