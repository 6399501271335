import { Descriptions } from 'antd';
import DescriptionsItem from 'antd/es/descriptions/Item';
import { useCityList } from 'apis/home';
import LOCALS from 'commons/locals';
import {
  GENDER_OPTION_LIST,
  SHOP_OPTION_LIST,
  findLabelByValue,
} from 'commons/options';
import { Trans } from 'react-i18next';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { UmsMember } from 'types/ums';
import formatTime from 'utils/formatTime';

type MemberBaseInfoProps = {
  member: UmsMember;
};

const MemberBaseInfo = ({ member }: MemberBaseInfoProps) => {
  const {
    email,
    countryCode,
    phone,
    firstName,
    lastName,
    firstNameKatakana,
    lastNameKatakana,
    gender,
    birthday,
    totalPayAmount,
    memberLevel,
    integration,
    pointExpireTime,
    createSource,
    createTime,
    country,
    city,
    detailAddress,
    socialAccount,
    socialName,
    remark,
  } = member;

  const { countryOptions } = useAppSelector(selectGlobalInfo);
  const cityList = useCityList(country);

  return (
    <Descriptions
      title={<Trans i18nKey={LOCALS.member_info} />}
      bordered
      column={{ xxl: 4, xl: 4, lg: 4, md: 2, sm: 2, xs: 2 }}
    >
      <Descriptions.Item label={<Trans i18nKey={LOCALS.first_name} />}>
        {firstName || '-'}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.last_name} />}>
        {lastName || '-'}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.first_name_katakana} />}>
        {firstNameKatakana || '-'}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.last_name_katakana} />}>
        {lastNameKatakana || '-'}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.email} />} span={2}>
        {email || '-'}
      </Descriptions.Item>

      <Descriptions.Item
        label={<Trans i18nKey={LOCALS.phone_number} />}
        span={2}
      >
        <div>
          {countryCode && <span>+{countryCode} </span>}
          <span>{phone || '-'}</span>
        </div>
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.gender} />} span={2}>
        {findLabelByValue(gender, GENDER_OPTION_LIST)}
      </Descriptions.Item>

      <Descriptions.Item label={<Trans i18nKey={LOCALS.birthday} />} span={2}>
        {birthday || '-'}
      </Descriptions.Item>

      <DescriptionsItem
        label={<Trans i18nKey={LOCALS.total_pay_amount} />}
        span={2}
      >
        {totalPayAmount}
      </DescriptionsItem>

      <DescriptionsItem
        label={<Trans i18nKey={LOCALS.member_level} />}
        span={2}
      >
        {memberLevel}
      </DescriptionsItem>

      <DescriptionsItem label={<Trans i18nKey={LOCALS.points} />} span={2}>
        {integration || 0}
      </DescriptionsItem>

      <DescriptionsItem
        label={<Trans i18nKey={LOCALS.points_expiration_time} />}
        span={2}
      >
        {formatTime(pointExpireTime)}
      </DescriptionsItem>

      <DescriptionsItem
        label={<Trans i18nKey={LOCALS.created_from} />}
        span={2}
      >
        {findLabelByValue(createSource, SHOP_OPTION_LIST)}
      </DescriptionsItem>

      <DescriptionsItem
        label={<Trans i18nKey={LOCALS.registration_time} />}
        span={2}
      >
        {formatTime(createTime)}
      </DescriptionsItem>

      <DescriptionsItem
        label={<Trans i18nKey={LOCALS.social_media} />}
        span={2}
      >
        {socialName || '-'}
      </DescriptionsItem>

      <DescriptionsItem
        label={<Trans i18nKey={LOCALS.social_handle} />}
        span={2}
      >
        {socialAccount || '-'}
      </DescriptionsItem>

      <DescriptionsItem
        label={<Trans i18nKey={LOCALS.country_region} />}
        span={2}
      >
        {findLabelByValue(country, countryOptions)}
      </DescriptionsItem>

      <DescriptionsItem
        label={<Trans i18nKey={LOCALS.state_province_city} />}
        span={2}
      >
        {city ? cityList.find(({ id }) => id === +city)?.name : '-'}
      </DescriptionsItem>

      <DescriptionsItem
        label={<Trans i18nKey={LOCALS.detail_address} />}
        span={4}
      >
        {detailAddress || '-'}
      </DescriptionsItem>

      <DescriptionsItem label={<Trans i18nKey={LOCALS.note} />} span={4}>
        {remark || '-'}
      </DescriptionsItem>
    </Descriptions>
  );
};

export default MemberBaseInfo;
