import { Spin } from 'antd';
import { getOmsOrderDetailById,getOmsOrderMultiplePayList } from 'apis/oms';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { OmsOrderDetail,OmsOrderMultiplePay } from 'types/oms';
import OrderStep from './step';
import OrderOperate from './order-operate';
import OrderBaseInfo from './base-info';
import styles from './index.module.scss';
import RecipientInformation from './recipient-information';
import OrderItemList from './item-list';
import OrderOperateHistories from './operate-histories';
import OrderPointHistories from './point-histories';
import OmsOrderPaymentInfo from './payment-info';
import OnlinePaymentRecord from './online-payment-record';
import OfflinePaymentRecord from './offline-payment-record';
import OmsOrderMultiplePayList from './OmsOrderMultiplePayList';

const OrderView = () => {
  const { id } = useParams<{ id: string }>();
  const [omsOrderDetail, setOmsOrderDetail] = useState<OmsOrderDetail>();
  const [omsOrderMultiplePayList, setOmsOrderMultiplePayList] = useState<OmsOrderMultiplePay []>([]);
  useEffect(() => {
    if (!id) return;
    getOmsOrderDetailById(+id).then((res) => {
      setOmsOrderDetail(res.data);
    });
    getOmsOrderMultiplePayList(+id).then((res) => {
      console.log('====',res)
      setOmsOrderMultiplePayList(res.data);
    });
  }, [id]);

  if (!omsOrderDetail) return <Spin />;

  const { status } = omsOrderDetail;

  return (
    <div>
      <OrderStep status={status} />
      <div className={styles.divider}></div>
      <OrderOperate omsOrderDetail={omsOrderDetail} />
      <div className={styles.divider}></div>
      <OrderBaseInfo omsOrderDetail={omsOrderDetail} />
      <div className={styles.divider}></div>
      <RecipientInformation omsOrderDetail={omsOrderDetail} />
      <div className={styles.divider}></div>
      <OrderItemList omsOrderDetail={omsOrderDetail} />
      <div className={styles.divider}></div>
      <OmsOrderPaymentInfo omsOrderDetail={omsOrderDetail} />
      <div className={styles.divider}></div>
      <OmsOrderMultiplePayList  omsOrderDetail={omsOrderDetail} data={omsOrderMultiplePayList} />
      <div className={styles.divider}></div>
      <OnlinePaymentRecord omsOrderDetail={omsOrderDetail} />
      <div className={styles.divider}></div>
      <OfflinePaymentRecord omsOrderDetail={omsOrderDetail} />
      <div className={styles.divider} />
      <OrderPointHistories orderId={omsOrderDetail.id} />
      <div className={styles.divider}></div>
      <OrderOperateHistories omsOrderDetail={omsOrderDetail} />
    </div>
  );
};

export default OrderView;
