export type ProductCategory = {
  id: number;
  name: string;
  nameJa: string;
  nameZh: string;
  value: string;
  children?: ProductCategory[];
  size: string;
  sizeJa: string;
  sizeZh: string;
};

export type ProductAttributeCode = {
  id: number;
  labelCn: string;
  labelEn: string;
  labelJa: string;
  value: string;
  enabled: boolean;
  categoryId: number;
  parentId: number;
  treeIds?: string;
};

export enum ProductAttributeCodeNames {
  COLOR = 'color',
  MATERIAL = 'material',
  STAMP = 'stamp',
  RANK = 'rank',
  TYPE = 'type',
  HUE = 'colors',
  HARDWARE = 'hardware',
  ACCESSORIES = 'accessories',
  COLLECTIONS = 'collection',
}

export type PmsProduct = {
  id: number;
  mainCategoryId: number;
  productCategoryId: number;
  name: number;
  pic: number;
  pic2: number;
  productSn: string;
  deleteStatus: number;
  publishStatus: number;
  price: number;
  rank: string;
  rankDescription: string;
  collections: string;
  color: string;
  subTitle: string;
  originalPrice: number;
  stock: number;
  albumPics: string;
  promotionType: number;
  brandName: string;
  createdFrom: number;
  createdBy: string;
  stockPlace: string;
  currency: string;
  createdTime?: string;
};

export type ProductUpdateInfo = {
  id: number;
  language: string;
  owner?: string;
  productSn: string;
  pic?: string;
  albumPics: string;
  currency: string;
  stockPlace: string;
  originalPrice: number;
  promotionType: number;
  rank: string;
  detailTitle: string;
  attrAccessory: string;
  attrColor?: string;
  attrColorRemark: string;
  attrColors: string;
  attrHardware?: string;
  attrHardwareRemark: string;
  attrMaterial: string;
  attrMaterialRemark: string;
  attrModel: string;
  attrModelFilter: string;
  attrRankDesc: string;
  attrSize: string;
  attrStamp: string;
  attrStampRemark: string;
  attrType: string;
  productCategoryId: number;
  i18nCn: any;
  i18nJa: any;
  i18nTw: any;
  sourceType: number;
  deleteStatus: number;
  publishStatus: number;
};

export type ProductCreateInfo = Omit<ProductUpdateInfo, 'id'>;
