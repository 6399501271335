import { OmsOrderMultiplePay, OmsOrderDetail } from 'types/oms';
import { setOmsOrderMultiplePayList } from 'apis/oms';
import LOCALS from 'commons/locals';
import { Trans,useTranslation } from 'react-i18next';
import i18n from 'i18n';
import React from 'react';
import { Descriptions, Button, message,InputNumber,Table } from 'antd';
import { useEffect, useState, useRef,useCallback } from 'react';
import { EditableProTable, ProForm,ProFormText, ProFormRadio } from '@ant-design/pro-components';
import type { ProColumns, ProFormInstance } from '@ant-design/pro-components';
import { useParams } from 'react-router-dom';
import { timeout } from  'utils/tools'


const OmsOrderMultiplePayList = ({
  data,
  omsOrderDetail,
}: {
  data: OmsOrderMultiplePay[];
  omsOrderDetail: OmsOrderDetail;
}) => {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({} as any), []);
  const { id } = useParams<{ id: string }>();
  const [dataSource, setDataSource] = useState(data);
  const [editable, setEditable] = useState(false);
  const [left, setLeft] = useState(0);
  const [limit, setLimit] = useState('');
  const [visible,setVisible] = useState(true);

  const formRef = useRef<ProFormInstance<any>>();
  const columns: ProColumns<any>[] = [
    {
      title: <Trans i18nKey={LOCALS.sortId} />,
      dataIndex: 'sortId',
      key: 'sortId',
      readonly: true,
    },
    {
      title: <Trans i18nKey={LOCALS['pay_amount']} />,
      dataIndex: 'needPayAmount',
      key: 'needPayAmount',
      width: 360,
      // valueType: (item) => ({
      //   type: 'money',
      //   locale: 'ja'
      // }),
      renderFormItem: (schema,config,form) => {
        return (<InputNumber type="number" precision={0}  style={{width: '300px'}} />) 
      },
      // renderText: (text: string) => {
      //   return parseFloat((text || '').toString()).toLocaleString('en-US')
      // }
    },
    {
      title: <Trans i18nKey={LOCALS.orderPayId} />,
      dataIndex: 'orderPayId',
      key: 'orderPayId',
      readonly: true,
    },
    {
      title: <Trans i18nKey={LOCALS.options} />,
      valueType: 'option',
      width: 200,
      render: (text, record, _, action) => [
        <Button
          type="link"
          disabled={record.orderPayId}
          key="editable"
          onClick={() => {
            action?.startEditable?.(record.sortId);
          }}
        >
          <Trans i18nKey={LOCALS.edit} />
        </Button>,
      ],
    },
  ];
  function tableHandler(rows: any) {
    
    let sum = 0;
    let i = 1;
    console.log(`tableHandler--`);
    for(let row of rows){
      if(!row.needPayAmount || row.needPayAmount <=0 || row.needPayAmount >= omsOrderDetail.payAmount){
        message.warning('Pay Amount is Wrong!');
        return false;
      }
      row.sortId = i++;
      let str = row.needPayAmount.toString();
      row.needPayAmount = parseFloat(str.replace(/,/g, ''));
      sum = sum + row.needPayAmount;
    }
    let diff = omsOrderDetail.payAmount - sum;
    if(diff < 0){
      message.warning('Pay Amount is Wrong!');
      return false;
    }
    setLeft(diff)
    setDataSource(rows);
    console.log('set left',diff)
    setVisible(false);
    setTimeout(function () {
      setVisible(true);
    }, 100);
  }
  function resetTable() {
    let paid = data.filter((item) => item.orderPayId)
    setDataSource(paid);
    setLeft(omsOrderDetail.payAmount)
    if(paid.length){
      setLeft(omsOrderDetail.payAmount - dataSource.map(item => item.needPayAmount || 0).reduce((a,b) => a+b ));
    }
    setVisible(true);
    setTimeout(function () {
      setVisible(true);
    }, 100);
  }
  function validate() {
    if (!id) {
      message.warning('orderId is null!');
      return false;
    }
    if(!dataSource.length){
      return true;
    }
    let sum = 0;
    for (let item of dataSource) {
      if (!item.needPayAmount || !parseInt(item.needPayAmount.toString())) {
        message.warning('Pay Amount is null!');
        return false;
      } else {
        sum = sum + parseInt(item.needPayAmount.toString());
      }
    }
    if (sum !== omsOrderDetail.payAmount) {
      message.warning('Pay Amount is Wrong!');
      return false;
    }
    return true;
  }
  function delay(time: 1000) {
    return new Promise(function (resolve, reject) {
      setTimeout(function () {
        resolve('');
      }, time);
    });
  }
  async function autoSet() {
    let sum = 0;
    let i = 1;
    let arr = [] as any[];
    let limitAmount = parseInt(limit) || 0;
    console.log(`limitAmount`, limitAmount)
    if(!limitAmount){
      message.warning('Limit Amount is Wrong');
      return false;
    }
    for(let row of data){
      if(row.orderPayId && row.needPayAmount){
        sum = sum + parseInt(row.needPayAmount.toString());
        arr.push(row)
        i = i + 1;
      }
    }

    let diff = omsOrderDetail.payAmount - sum;
    if(diff / limitAmount > 100){
      message.warning('Limit Amount better be larger');
      return false;
    }
    while(diff > limitAmount){ 
      diff = diff - limitAmount;
      arr.push({ sortId: i.toString(), needPayAmount: limitAmount,orderPayId: ''});
      i = i + 1;
    }
    arr.push({ sortId: i.toString(), needPayAmount: diff, orderPayId: ''})
    if(arr.length > 100){
      message.warning('Limit Amount better be larger');
      return false;
    }
    setDataSource(arr);
    setLeft(0);
    setVisible(false);
    setTimeout(function () {
      setVisible(true);
    }, 100);
  }
  async function formHandler() {
    if (!validate()) return;
    let res = await setOmsOrderMultiplePayList(
      parseInt(id || ''),
      dataSource.filter((item) => !item.orderPayId)
    );
    if (res.code === 200) {
      message.success('Success!');
      await delay(1000);
      window.location.reload();
    }
  }
  useEffect(() => {
    console.log(`useEffect`)
    if (omsOrderDetail.status === 0) {
      setEditable(true);
    }
  }, []);
  return (
    <div className={editable ? 'OmsOrderMultiplePayList' : 'OmsOrderMultiplePayList readonly'} style={{minHeight: `500px`}}>
      <ProForm
        formRef={formRef}
        validateTrigger="onBlur"
        disabled={!editable}
        submitter={{
          onSubmit: formHandler,
          onReset: resetTable
        }}
      >
        <Descriptions
          title={<Trans i18nKey={LOCALS.MultiplePayList} />}
        ></Descriptions>
        <div style={{display: 'flex', alignItems: 'center',marginLeft:'25px',marginBottom: '25px'}}>
        <InputNumber type="number" precision={0} value={limit} onChange={(v)=>{ setLimit(v || '')}} placeholder={i18n.t(LOCALS.AutoSplit) || ''}  style={{width: '600px'}}/>
        <Button  onClick={autoSet}  type="primary" style={{marginLeft: '10px',width: '100px'}}><Trans i18nKey={LOCALS.Auto}/></Button>
        </div>
        {visible ? <EditableProTable
          rowKey={'sortId'}
          columns={columns}
          onChange={tableHandler}
          value={dataSource}
          style={{minHeight: `300px`}}
          recordCreatorProps={{
            style: {
              visibility: left ?'visible':'hidden'
            },
            record: () => {
              return { sortId: dataSource.length + 1, needPayAmount: left, orderPayId: null}
            }
          }}
          editable={{
            type: 'single',
            onlyAddOneLineAlertMessage: (
              <Trans i18nKey={LOCALS.saveFirst}></Trans>
            ),
          }}
        />:
        // This is a temp view during re-render the table 
        // <div  style={{minHeight: '300px'}}></div>
          <Table rowKey={'sortId'} columns={columns.slice(0,1) as any[]} dataSource={dataSource}  style={{minHeight: `300px`}} />
        }
      </ProForm>
    </div>
  );
};
export default OmsOrderMultiplePayList;
