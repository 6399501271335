import { useState } from 'react';
import SearchMember from './search-member';
import { UmsMember } from 'types/ums';
import SearchProduct from './search-product';
import styles from './index.module.scss';
import { PmsProduct } from 'types/pms';
import CreateOrderDetail from './order-detail';

const OrderCreate = () => {
  const [umsMember, setUmsMember] = useState<UmsMember>();
  const [pmsProductList, setPmsProductList] = useState<PmsProduct[]>([]);

  return (
    <div>
      <SearchMember onUmsMemberChange={setUmsMember} />
      <div className={styles.divider} />
      <SearchProduct onPmsProductListChange={setPmsProductList} />
      <div className={styles.divider} />
      <CreateOrderDetail
        umsMember={umsMember}
        pmsProductList={pmsProductList}
      />
    </div>
  );
};

export default OrderCreate;
