import { Button, Form, Input, Select, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { GetMemberListDto, getMemberList } from 'apis/ums';
import useIsMobile from 'commons/hooks/useIsMobile';
import usePagination from 'commons/hooks/usePagination';
import LOCALS from 'commons/locals';
import {
  MEMBER_LEVEL_MAP,
  MEMBER_LEVEL_OPTION_LIST,
  SHOP_MAP,
  SHOP_OPTION_LIST,
  findLabelByValue,
} from 'commons/options';
import i18n from 'i18n';
import { useCallback, useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PageQuery } from 'types/base';
import { UmsMember } from 'types/ums';
import formatTime from 'utils/formatTime';

const MemberList = () => {
  const [form] = Form.useForm<GetMemberListDto>();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const {
    loading,
    setLoading,
    pageNum,
    setPageNum,
    pageSize,
    setPageSize,
    total,
    setTotal,
    dataSource,
    setDataSource,
  } = usePagination<UmsMember>();

  const getDataSource = useCallback(
    async ({ pageNum, pageSize }: PageQuery) => {
      const data = form.getFieldsValue();

      try {
        setLoading(true);
        const {
          data: { list, total },
        } = await getMemberList({
          ...data,
          pageNum,
          pageSize,
        });

        setDataSource(list);
        setTotal(total);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [form, setDataSource, setLoading, setTotal]
  );

  useEffect(() => {
    getDataSource({ pageNum: 1, pageSize: 10 });
  }, [getDataSource]);

  const onFinish = async () => {
    setPageNum(1);
    getDataSource({ pageNum: 1, pageSize });
  };

  const onReset = () => {
    form.resetFields();
    onFinish();
  };

  const onAdd = () => {
    navigate('/ums/member-add');
  };

  const onViewDetails = useCallback(
    (id: UmsMember['id']) => {
      navigate(`/ums/member-view/${id}`);
    },
    [navigate]
  );

  const onEdit = useCallback(
    (id: UmsMember['id']) => {
      navigate(`/ums/member-edit/${id}`);
    },
    [navigate]
  );

  const columns: ColumnsType<UmsMember> = useMemo(() => {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: <Trans i18nKey={LOCALS.email} />,
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: <Trans i18nKey={LOCALS.name} />,
        key: 'name',
        render: ({ firstName, lastName, nickname }: UmsMember) => {
          if (firstName && lastName) {
            return `${firstName} ${lastName}`;
          }

          return nickname || '-';
        },
      },
      {
        title: <Trans i18nKey={LOCALS.total_pay_amount} />,
        dataIndex: 'totalPayAmount',
        key: 'totalPayAmount',
      },
      {
        title: <Trans i18nKey={LOCALS.points} />,
        key: 'integration',
        dataIndex: 'integration',
        render: (points) => {
          return points || 0;
        },
      },
      {
        title: <Trans i18nKey={LOCALS.phone_number} />,
        key: 'phone',
        render: ({ countryCode, phone }: UmsMember) => {
          return (
            <div>
              {countryCode && <span>+{countryCode} </span>}
              <span>{phone || '-'}</span>
            </div>
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.created_from} />,
        key: 'createSource',
        dataIndex: 'createSource',
        render: (createSource: number) => {
          return findLabelByValue(
            createSource || SHOP_MAP.WEBSITE,
            SHOP_OPTION_LIST
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.member_level} />,
        key: 'memberLevel',
        dataIndex: 'memberLevel',
        render: (memberLevel: string) => {
          const COLOR_MAP = {
            [MEMBER_LEVEL_MAP.BRONZE]: '#A57E52',
            [MEMBER_LEVEL_MAP.SILVER]: '#C0C0C0',
            [MEMBER_LEVEL_MAP.GOLD]: '#FFD700',
          };

          return (
            <span
              style={{
                color: COLOR_MAP[memberLevel],
              }}
            >
              {memberLevel}
            </span>
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.registration_time} />,
        key: 'createTime',
        dataIndex: 'createTime',
        render: (createTime: string) => {
          return formatTime(createTime);
        },
      },
      {
        title: <Trans i18nKey={LOCALS.created_by} />,
        key: 'createdBy',
        dataIndex: 'createdBy',
        render: (createdBy: string) => {
          return createdBy || '-';
        },
      },
      {
        key: 'options',
        title: <Trans i18nKey={LOCALS.options} />,
        render: ({ id }: UmsMember) => {
          return (
            <div>
              <Button
                type="link"
                onClick={() => {
                  onViewDetails(id);
                }}
              >
                <Trans i18nKey={LOCALS.details} />
              </Button>

              <Button
                type="link"
                onClick={() => {
                  onEdit(id);
                }}
              >
                <Trans i18nKey={LOCALS.edit} />
              </Button>
            </div>
          );
        },
      },
    ];
  }, [onEdit, onViewDetails]);

  return (
    <div>
      <Form
        form={form}
        layout={isMobile ? 'vertical' : 'inline'}
        onFinish={onFinish}
      >
        <Form.Item name="keyword" label={<Trans i18nKey={LOCALS.keyword} />}>
          <Input placeholder={i18n.t(LOCALS.please_enter) || ''} />
        </Form.Item>

        <Form.Item
          name="createSource"
          label={<Trans i18nKey={LOCALS.created_from} />}
        >
          <Select
            placeholder={i18n.t(LOCALS.please_select) || ''}
            style={{ minWidth: 120 }}
            options={SHOP_OPTION_LIST}
          />
        </Form.Item>

        <Form.Item
          name="memberLevel"
          label={<Trans i18nKey={LOCALS.member_level} />}
        >
          <Select
            placeholder={i18n.t(LOCALS.please_select) || ''}
            style={{ minWidth: 120 }}
            options={MEMBER_LEVEL_OPTION_LIST}
          />
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              <Trans i18nKey={LOCALS.search} />
            </Button>

            <Button htmlType="button" onClick={onReset}>
              <Trans i18nKey={LOCALS.reset} />
            </Button>
            <Button onClick={onAdd}>
              <Trans i18nKey={LOCALS.add} />
            </Button>
          </Space>
        </Form.Item>
      </Form>

      <Table
        pagination={{
          total,
          pageSize,
          current: pageNum,
          onChange: (page, pageSize) => {
            setPageNum(page);
            setPageSize(pageSize);
            getDataSource({ pageNum: page, pageSize });
          },
        }}
        loading={loading}
        rowKey={'id'}
        style={{
          marginTop: 12,
        }}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default MemberList;
