import { OmsOrderDetail, OmsOrderOperateHistory } from 'types/oms';
import styles from './index.module.scss';
import LOCALS from 'commons/locals';
import { Trans } from 'react-i18next';
import { Table } from 'antd';
import { useMemo } from 'react';
import { ColumnsType } from 'antd/es/table';
import formatTime from 'utils/formatTime';
import { useAppSelector } from 'store/hooks';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { findLabelByValue } from 'commons/options';

type OrderOperateNotesProps = {
  omsOrderDetail: OmsOrderDetail;
};

const OrderOperateHistories = ({
  omsOrderDetail: { historyList },
}: OrderOperateNotesProps) => {
  const { orderStatusOptions } = useAppSelector(selectGlobalInfo);

  const columns: ColumnsType<OmsOrderOperateHistory> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.operator} />,
        dataIndex: 'operateMan',
        key: 'operateMan',
      },

      {
        title: <Trans i18nKey={LOCALS.created_time} />,
        dataIndex: 'createTime',
        key: 'createTime',
        render: (createTime: OmsOrderOperateHistory['createTime']) => {
          return formatTime(createTime);
        },
      },
      {
        title: <Trans i18nKey={LOCALS.order_status} />,
        dataIndex: 'orderStatus',
        key: 'orderStatus',
        render: (orderStatus: OmsOrderOperateHistory['orderStatus']) => {
          return findLabelByValue(orderStatus, orderStatusOptions);
        },
      },
      {
        title: <Trans i18nKey={LOCALS.note} />,
        dataIndex: 'note',
        key: 'note',
      },
    ];
  }, [orderStatusOptions]);

  return (
    <div>
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.operate_histories} />
      </div>

      <Table
        rowKey={'id'}
        pagination={false}
        columns={columns}
        dataSource={historyList || []}
      />
    </div>
  );
};

export default OrderOperateHistories;
