import { Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { getOrderList } from 'apis/oms';
import usePagination from 'commons/hooks/usePagination';
import LOCALS from 'commons/locals';
import { useCallback, useEffect, useMemo } from 'react';
import { Trans } from 'react-i18next';
import { PageQuery } from 'types/base';
import { OmsOrder } from 'types/oms';
import { UmsMember } from 'types/ums';
import styles from './index.module.scss';
import formatTime from 'utils/formatTime';
import {
  ORDER_CREATED_FROM_OPTION_LIST,
  ORDER_STATUS_ANTD_TAG_COLOR_MAP,
  findLabelByValue,
} from 'commons/options';
import showTotal from 'components/show-total';
import { selectGlobalInfo } from 'store/slices/globalInfoSlice';
import { useAppSelector } from 'store/hooks';
import LinkButton from 'components/link-button';

type MemberOrderListProps = {
  memberId: UmsMember['id'];
};

const MemberOrderList = ({ memberId }: MemberOrderListProps) => {
  const {
    loading,
    setLoading,
    pageNum,
    setPageNum,
    pageSize,
    setPageSize,
    total,
    setTotal,
    dataSource,
    setDataSource,
  } = usePagination<OmsOrder>();

  const { orderStatusOptions } = useAppSelector(selectGlobalInfo);

  const getDataSource = useCallback(
    async ({ pageNum, pageSize }: PageQuery) => {
      try {
        setLoading(true);
        const {
          data: { list, total },
        } = await getOrderList({
          memberId: `${memberId}`,
          pageNum,
          pageSize,
        });

        setDataSource(list);
        setTotal(total);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    },
    [memberId, setDataSource, setLoading, setTotal]
  );

  useEffect(() => {
    getDataSource({ pageNum: 1, pageSize: 10 });
  }, [getDataSource]);

  const columns: ColumnsType<OmsOrder> = useMemo(() => {
    return [
      {
        title: <Trans i18nKey={LOCALS.order_sn}></Trans>,
        dataIndex: 'orderSn',
        key: 'orderSn',
        render: (orderSn: OmsOrder['orderSn'], omsOrder: OmsOrder) => {
          return (
            <LinkButton href={`/oms/order-view/${omsOrder.id}`}>
              {orderSn}
            </LinkButton>
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.product_sn}></Trans>,
        dataIndex: 'orderItemList',
        key: 'orderItemList',
        render: (orderItemList: OmsOrder['orderItemList']) => {
          return (
            <div>
              {orderItemList.map(({ productSn, productId }) => {
                return (
                  <div key={productSn}>
                    <LinkButton href={`/pms/product-view/${productId}`}>
                      {productSn}
                    </LinkButton>
                  </div>
                );
              })}
            </div>
          );
        },
      },
      {
        title: <Trans i18nKey={LOCALS.created_time}></Trans>,
        dataIndex: 'createTime',
        key: 'createTime',
        render: (createTime: OmsOrder['createTime']) => {
          return formatTime(createTime);
        },
      },
      {
        title: <Trans i18nKey={LOCALS.pay_amount}></Trans>,
        dataIndex: 'payAmount',
        key: 'payAmount',
        render: (payAmount: OmsOrder['payAmount']) => {
          return `JPY ${payAmount.toLocaleString()}`;
        },
      },
      {
        title: <Trans i18nKey={LOCALS.created_from}></Trans>,
        dataIndex: 'createdFrom',
        key: 'createdFrom',
        render: (createdFrom: OmsOrder['createdFrom']) => {
          return findLabelByValue(createdFrom, ORDER_CREATED_FROM_OPTION_LIST);
        },
      },

      {
        title: <Trans i18nKey={LOCALS.order_status}></Trans>,
        dataIndex: 'status',
        key: 'status',
        render: (status: OmsOrder['status']) => {
          return (
            <Tag color={ORDER_STATUS_ANTD_TAG_COLOR_MAP[status]}>
              {findLabelByValue(status, orderStatusOptions)}
            </Tag>
          );
        },
      },
    ];
  }, [orderStatusOptions]);

  return (
    <div>
      <div className={styles.title}>
        <Trans i18nKey={LOCALS.order_list} />
      </div>
      <Table
        pagination={{
          showTotal,
          total,
          pageSize,
          current: pageNum,
          onChange: (page, pageSize) => {
            setPageNum(page);
            setPageSize(pageSize);
            getDataSource({ pageNum: page, pageSize });
          },
        }}
        loading={loading}
        rowKey={'id'}
        style={{
          marginTop: 12,
        }}
        dataSource={dataSource}
        columns={columns}
      />
    </div>
  );
};

export default MemberOrderList;
